
import React, {useEffect, useRef, RefObject} from "react";

import {
  Edit,
  EditProps,
  SimpleForm,
  ImageField,
  ImageInput,
  FileField,
  FileInput,
  useRecordContext,
  RaRecord
} from 'react-admin';

import {
  SaveCancelToolbar,
  LanguageInputField,
  ImagePlaceholder,
  VideoPlaceholder
} from "../../components";

import { maxImageDimensions, maxFileSize } from "../../utils";
import { MaxVideoSize, MaxPosterSize, MaxPosterDimensions, transformVideoRecord } from "./videoFileUtils";
import { VideoFileDetails } from "./videofileDetails";
import { VideoFile } from "../../models";
import {PutResult} from "@aws-amplify/storage";

const MediaFilesTitle = () => {
  const record = useRecordContext<VideoFile>()
  return <span>Video - { record ? record.videoFileName : '' }</span>
}

export const VideoFileEdit = (props:EditProps) => {

  const uploadList = useRef<Promise<PutResult>[]>([]);

  const transform = (record: RaRecord): RaRecord => {
    return transformVideoRecord(record as VideoFile, uploadList);
  }

  useEffect(() => {
    console.log('UPLOADS',uploadList.current);
  }, [uploadList]);

  return (
    <Edit
      title={<MediaFilesTitle />}
      {...props}
      transform={transform}
      actions={false}
      mutationMode='pessimistic'
      aside={<VideoFileDetails/>}
    >

      <SimpleForm toolbar={<SaveCancelToolbar />} >
        <LanguageInputField source='languages'/>
        <ImageInput
          label='Upload new video poster'
          source='_posterStorageKey'
          accept='image/png,image/jpeg'
          validate={[
            maxFileSize(MaxPosterSize),
            maxImageDimensions(MaxPosterDimensions)
          ]}
          placeholder={
            <ImagePlaceholder
              fileTypes={['*.jpg','*.png']}
              maxFileSize={MaxPosterSize}
              maxDimensions={MaxPosterDimensions}
            />}
        >
          <ImageField source='_posterStorageKey' />
        </ImageInput>
        <FileInput
          label="Upload new video"
          source="_videoStorageKey"
          accept="video/mp4"
          validate={[
            maxFileSize(MaxVideoSize),
          ]}
          placeholder={
            <VideoPlaceholder
              maxFileSize={MaxVideoSize}
              fileTypes={['*.mp4']}
            />
          }
        >
          <FileField
            source="_videoStorageKey"
            title="title"
            target="_blank"
          />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
};
