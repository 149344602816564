import React, { useState, useEffect } from 'react';
import {AuthChallengeProps} from "./authenticationPage";
import {ConfirmedPasswordInput, ConfrimedPasswordForm} from './confirmedPasswordInput';
import {TermsAndConditionsCheckbox} from './termsAndConditionsCheckbox';
import {Auth} from 'aws-amplify';
import {LoginChallenge} from '../../services';
import {useForm, SubmitHandler} from 'react-hook-form';
import {AuthTab} from './authenticationPage';
import {Alert, Button, Collapse, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import _ from 'lodash';
import { LoginParams } from '../../services';
import {useLogin} from 'react-admin';
import {PasswordPolicyInfo} from '../../utils';

type NewPasswordParams = ConfrimedPasswordForm;

export const NewPasswordChallengeForm = (props: AuthChallengeProps) => {

  const login = useLogin();
  const [submitError, setSubmitError] = useState<any|null>(null);
  const {changeTab, challenge} = props;
  const formController = useForm<NewPasswordParams>({mode:'onChange'});
  const {reset, handleSubmit, formState: {isValid, isDirty, isSubmitting, errors}} = formController;

  const completeChallenge: SubmitHandler<NewPasswordParams> = async ( data): Promise<void> => {
    if (challenge !== undefined) {
      try {
        const { user } = challenge as LoginChallenge
        const result = await Auth.completeNewPassword(user, data.password)

        const loginParams: LoginParams = {
          username: user.getUsername(),
          password: data.password
        }
        login(loginParams);

      } catch (error) {
        console.log('completeChallege', error)
        setSubmitError(error)
        reset()
      }
    }
  }

  useEffect(() => {
    isDirty && setSubmitError(null)
  }, [isDirty]);

  const needsHint = (errors: any):boolean => {
    return _.get(errors, 'password.type') === 'validate';
  }

  const returnToSignIn = () => {
    changeTab(AuthTab.SignIn);
  }

  return (
    <>
      <form onSubmit={handleSubmit(completeChallenge)}>
        <Stack direction="column" gap={1}>
          <Button
            sx={{ marginBottom: '2px' }}
            size="small"
            onClick={returnToSignIn}
          >
            return to sign in
          </Button>

          <Alert severity="info" sx={{ maxWidth: '270px' }}>
            <span>Please create a new password to complete your sign up.</span>
          </Alert>

          <ConfirmedPasswordInput formController={formController} />

          <Collapse in={needsHint(errors)}>
            <Alert severity="warning" sx={{ maxWidth: '270px' }}>
              <span>{PasswordPolicyInfo}</span>
            </Alert>
          </Collapse>

          <TermsAndConditionsCheckbox formController={formController} />

          <Collapse in={submitError!== null} sx={{ maxWidth: '261px' }}>
            <Alert severity="error">{_.get(submitError, 'message', '')}</Alert>
          </Collapse>

          <LoadingButton
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || !isDirty}
            variant="contained"
            color="primary"
          >
            create password
          </LoadingButton>
        </Stack>
      </form>
    </>
  )
}
