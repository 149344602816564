import React, {RefObject} from "react";

import { PutResult } from '@aws-amplify/storage'
import { DocumentFile } from "../../models";
import { transformFileAttachment, RecordFileAttachment } from "../../services";

export const transformDocumentRecord = (record:DocumentFile, uploadProcessList?: RefObject<Promise<PutResult>[]>) => {
    const attachment: RecordFileAttachment = {
      access: record.protected ? 'authenticated' : 'all',
      sourceProperty: '_documentStorageKey',
      filenameProperty: 'documentFileName',
      destinationProperty: 'documentStorageKey',
      storageOptions: {level: 'public'}
    };
    return transformFileAttachment(record, [attachment], uploadProcessList);
  }

export const MaxDocumentSize:number = 10000000; //10Mb
