
import React  from 'react';

import {
  required,
  SimpleForm,
  TextInput,
  Create,
  CreateProps,
  useDataProvider
} from 'react-admin';

import {
  Box,
  Grid
} from '@mui/material';

import { SaveCancelToolbar, } from '../../components';
import {TranslationKeyInput} from './translationKeyInput';
import { PlaylistReferenceInput } from "./playlistInput";
import { DocumentListInput } from './documentListInput';
import { AddDocumentInput } from './addDocumentInput';

import {LanguageLookupProvider} from '../../components';
import {DocumentLookupProvider} from './documentLookup';
import { FormError } from '../../utils';
import { ContentPage } from '../../models';

export const ContentPageCreate = (props: CreateProps) => {

  const dataProvider = useDataProvider();

  const validateForm = async (data:Partial<ContentPage>):Promise<FormError<ContentPage>> => {
    const errors:FormError<ContentPage> ={};

    if (data.uniqueName === undefined || data.uniqueName === '') {
      errors.uniqueName = 'Name required';
    }
    else
    {
      const contentPages = await dataProvider.getList(
        'ContentPage',
        {
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'uniqueName', order: 'DESC' },
          filter: {
            uniqueName: { eq: data.uniqueName},
          }
        }
      );

      if (contentPages.total! > 0) {
        errors.uniqueName= 'Page already exists';
      }
    }

    return errors
  };

  return (
    <Create
      {...props}
      title='Add Page'
      redirect='list'
    >
      <DocumentLookupProvider>
        <LanguageLookupProvider>
          <SimpleForm
            redirect='list'
            toolbar={<SaveCancelToolbar />}
            validate={validateForm}
          >
            <TextInput
              source='uniqueName'
              label='Name'
              variant='standard'
              validate={required()}
            />
            <Box maxWidth='750px'>
              <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid item >
                  <TranslationKeyInput label='Title Translation Key' source='titleTranslationKey'/>
                </Grid>
                <Grid item >
                  <TranslationKeyInput label='Slogan Translation Key' source='sloganTranslationKey'/>
                </Grid>
                <Grid item >
                  <TranslationKeyInput label='Features Translation Key' source='featuresTranslationKey'/>
                </Grid>
                <Grid item >
                  <TranslationKeyInput label='Benefits Translation Key' source='benefitsTranslationKey'/>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{height:'1.5rem'}}/>
            <PlaylistReferenceInput label='Playlist'/>
            <Box sx={{height:'2rem'}}/>
            <DocumentListInput/>
            <AddDocumentInput/>
          </SimpleForm>
        </LanguageLookupProvider>
      </DocumentLookupProvider>
    </Create>
  );
}


