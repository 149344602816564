import React, {RefObject} from "react";

import { PutResult } from '@aws-amplify/storage'
import { VideoFile } from "../../models";
import { transformFileAttachment, RecordFileAttachment } from "../../services";
import { ImageDimensions } from "../../utils";

export const transformVideoRecord = (record: VideoFile, uploadProcessList?: RefObject<Promise<PutResult>[]>) => {
    const posterAttachment: RecordFileAttachment = {
      access: record.protected ? 'authenticated' : 'all',
      sourceProperty: '_posterStorageKey',
      filenameProperty: 'posterFileName',
      destinationProperty: 'posterStorageKey',
      storageOptions: {level: 'public'}
    };

    const videoAttachment: RecordFileAttachment = {
      access: record.protected ? 'authenticated' : 'all',
      sourceProperty: '_videoStorageKey',
      filenameProperty: 'videoFileName',
      destinationProperty: 'videoStorageKey',
      storageOptions: {level: 'public'}
    };

    return transformFileAttachment(record, [posterAttachment,videoAttachment], uploadProcessList);
  }

export const MaxVideoSize:number = 1000000000; //1Gb
export const MaxPosterSize:number = 5000000; //2.5Mb
export const MaxPosterDimensions:ImageDimensions = {width:1920, height: 1080};
