import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({name:'Footer'})(theme => ({
    root: {
      marginTop: '1.5em',
      marginBottom: '1em',
      maxWidth: '1080px',
      display: 'flex',
      justifyContent: 'left',
    },
}));


export interface FooterProps { legalPath:string, hideOnPaths:string[] }
export const Footer = ({legalPath, hideOnPaths} :any) => {

  const location = useLocation();
  const {classes} = useStyles();

  return (
    <div className={classes.root}>
      <Link target='_blank' to={legalPath} hidden={hideOnPaths.includes(location.pathname)}>
        <Typography variant="caption"> Imprint - Privacy Policy - Legal</Typography>
      </Link>
    </div>
  )
}

