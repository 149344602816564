export const PasswordPolicyInfo = 'Passwords need to be at least 8 characters long, include a uppercase letter, a lowercase letter, a number and a special character.'

export const validatePassword = (input: string) : (string | boolean) => {
  if (input.length < 8) {
    return 'Password must be at least 8 characters long.'
  }
  if (input.length > 99){
    return 'Password must be less than 99 characters long.'
  }
  if (!/[A-Z]/.test(input)) {
    return 'Password must include at least one uppercase letter.'
  }
  if (!/[a-z]/.test(input)) {
    return 'Password must include at least one lowercase letter.'
  }
  if (!/[0-9]/.test(input)) {
    return 'Password must include at least one number.'
  }
  if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(input))
  {
    return 'Password must include at least one special character.'
  }
  return true
}
