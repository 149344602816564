import {AuthProvider, UserIdentity} from 'react-admin';
import {Auth, CognitoUser} from '@aws-amplify/auth';

export interface LoginParams {
  username: string;
  password: string;
}

export class LoginChallenge {
  challengeName: LoginChallengeName;
  user: CognitoUser;

  constructor(user: CognitoUser, challengeName: LoginChallengeName) {
    this.challengeName = challengeName;
    this.user = user;
  }
}

type LoginChallengeName = 
(
  'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'SELECT_MFA_TYPE' |
  'MFA_SETUP' | 'PASSWORD_VERIFIER' | 'CUSTOM_CHALLENGE' |
  'DEVICE_SRP_AUTH' | 'DEVICE_PASSWORD_VERIFIER' |
  'ADMIN_NO_SRP_AUTH' | 'NEW_PASSWORD_REQUIRED'
);

export const AuthAdapter: AuthProvider = {

  login: async ({username, password}: LoginParams): Promise<CognitoUser> => {
    const user = await Auth.signIn(username, password) as CognitoUser & {challengeName?: LoginChallengeName};
    if (user.challengeName) {// && user.challengeName! === 'NEW_PASSWORD_REQUIRED') {
      /* The user signin is not completed yet a user is returned.
       * Returning the user would trigger a routing to the main admin page.
       * Without a completed sigin the user would be redirected to the logini again page.
       */
      // eslint-disable-next-line no-throw-literal
      throw new LoginChallenge(user, user.challengeName);
    }
    return user;
  },

  getIdentity: async ():Promise<UserIdentity> =>{
    const userInfo = await Auth.currentUserInfo()
    return {
      id: userInfo.username,
      fullName: userInfo.attributes.email.split('@')[0]
    }
  },

  checkAuth: async () => {
    try {
      const session = await Auth.currentSession();
      const groups: string[] = session.getIdToken().payload['cognito:groups'];

      const isAuthorized = groups.length > 0

      if (!isAuthorized) {
        throw new Error('Current user is not authorized.')
      }

      if (!session.isValid())
        throw new Error('Current session is not valid.');
    }
    catch (error) {
      console.error(error);
      // eslint-disable-next-line no-throw-literal
      throw {redirectTo: '/login'};
    }
  },

  logout: async () => {
    try {
      await Auth.signOut();
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  },

  checkError: async (error: any) => {
  },

  getPermissions: async (): Promise<Array<'Admin'|'Team'|'Customer'>> => {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload['cognito:groups'];
    return groups ? groups as Array<'Admin' | 'Team' | 'Customer'> : [];
  }
};

