import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  ProviderProps
} from 'react';

import {useGetList} from 'react-admin';
import {Language} from '../../models';
import {DatastorePagination} from '../../services';

export type LanguageInfo = Omit<Language, 'translations' | 'imageFiles' | 'videoFiles' | 'documentFiles'> &
{
  translations: [string],
  imageFiles: [string],
  videoFiles: [string],
  documentFiles:[string]
}

export const LanguageLookupContext =
  createContext<[loading:boolean, lookup:Map<string,LanguageInfo>]>([true,new Map<string,LanguageInfo>()])

export const LanguageLookupProvider =
  (props: Omit<ProviderProps<[loading: boolean, lookup: Map<string, LanguageInfo>]>, 'value'>) =>
{
  const value = useLanguageLookup();
  return <LanguageLookupContext.Provider value={value} {...props}> {props.children} </LanguageLookupContext.Provider>
}

export const useLanguageLookupContext = () => useContext(LanguageLookupContext);

export const useLanguageLookup = (): [loading: boolean, lookup: Map<string, LanguageInfo>] => {

  const [languageLookup, setLanguageLookup] = useState(new Map<string, LanguageInfo>());
  const [isLoading, setIsLoading] = useState(true);

  const {data: languages, isLoading:languagesLoading} = useGetList('Language', {pagination:DatastorePagination.All})

  useEffect(() => {
    if ( languages && !languagesLoading) {
      const lookup = new Map(languages.map(l => [l.id, l]));
      setLanguageLookup(lookup);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [languages,isLoading]);

  return [isLoading,languageLookup];
}

