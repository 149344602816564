import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  useDataProvider
} from 'react-admin';

import { SaveCancelToolbar } from '../../components';
import { TranslationKey } from '../../models';
import { FormError } from '../../utils';

export const TranslationKeyCreate = (props: CreateProps) => {
  const dataProvider = useDataProvider();

  const parseInput = (input: string) => {
    input = input.toUpperCase();
    input = input.replace(" ", "_");
    return input;
  }

  const keyIsUnique = async (data:Partial<TranslationKey>) => {
    const errors:FormError<TranslationKey> ={};

    const translationKeys = await dataProvider.getList(
      'TranslationKey',
      {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'key', order: 'DESC' },
        filter: { key: { eq:data.key } }
      }
    )

    if (translationKeys.total! > 0) {
      errors.key = 'key already exists';
    }
    return errors
  };

  return (
    <Create
      {...props}
      title='Add TranslationKey'
    >
      <SimpleForm
        toolbar={<SaveCancelToolbar />}
        validate={keyIsUnique}
      >
        <TextInput
          sx={{minWidth:'300px'}}
          source="key"
          label="Translation key"
          variant='standard'
          parse={parseInput}
        />
      </SimpleForm>
    </Create>
  )
}


