import {
  Button,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  CreateBase,
  Form,
  SaveButton,
  Toolbar,
  useNotify,
  ValidationErrorMessage,
  usePermissions,
  useRefresh
} from 'react-admin';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Stack,
  Box
} from '@mui/material';

import { User } from "../../services";
import { Validate } from '../../utils';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { GroupNames } from "../../services";
import {useNavigate}from 'react-router-dom';

export const UserCreateDialog = () =>{
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();

  const {isLoading,permissions} = usePermissions();

  const closeDialog = () => {
    navigate(-1);
  }

  const onSuccess = (data:User) => {
    notify(`Invitation mail sent to ${data.email}.`, {type: 'success', undoable: false});
    navigate(-1);
    refresh();
  };

  const validateMail = (address: string):ValidationErrorMessage|undefined => {
    const result = Validate.email(address);
    if (result === true) return undefined;
    return result as string;
  }

  const setSignUpLocation = (record: User):User => {
    switch (record.group) {
      case ('Admin'):
      case ('Team'):
        record.signUpLocation = 'CMS';
        break;
      default:
        record.signUpLocation = 'SHOWROOM';
        break;
    }
    return record;
  }

  return (
    <Dialog open onClose={closeDialog}>
      <Box minWidth='350px'>
        <CreateBase mutationOptions={{onSuccess}} transform={setSignUpLocation}>
          <Form >
            <DialogTitle>Invite User</DialogTitle>
            <Divider/>
            <DialogContent>
              <Stack gap='0px' spacing='0px'>
                <TextInput source='email' validate={[required(),validateMail]} variant='standard'/>
                <ReferenceInput label='User Type' source='group' reference='groups'>
                  <SelectInput
                    label='User Type'
                    optionText='groupName'
                    defaultValue='Customer'
                    validate={[required()]}
                    variant='standard'
                    isLoading={isLoading}
                    disabled={!permissions || !permissions.includes(GroupNames.Admin) }
                  />
                </ReferenceInput>
              </Stack>
            </DialogContent>
            <Toolbar sx={{ justifyContent:'space-between'}}>
                <Button label='cancel' onClick={closeDialog}  startIcon={<CloseIcon/>} size='medium' />
                <SaveButton label="send" icon={<SendIcon/>}/>
            </Toolbar>
          </Form>
        </CreateBase>
      </Box>
    </Dialog>
  )
}


