import React from 'react';
import { Box, Typography } from '@mui/material';

import { useRecordContext } from 'ra-core';
import { useDetailPanelStyles } from '../../theme';
import { usePresignedUrl } from '../storage/usePresignedUrl';
import { PublicFieldProps, LinearProgress} from 'react-admin';

import _ from 'lodash';

export const DetailsFileField = (props:PublicFieldProps & {nameSource: string}) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();
  const {source, nameSource} = props;

  const [loading, url, error] = usePresignedUrl(_.get(record,source ?? '',undefined));

  return (
    <>
      <Typography
        className={styles.classes.label}
        variant="caption"
      >
        {props.label}
      </Typography>

      {(loading || error) ?
        <LinearProgress sx={{width:'100%'}} />
        :
        <Box className={styles.classes.field}>
          <a
            href={url}
            title={record[nameSource]}
            target="_blank"
            rel="noreferrer"
          >
            {record[nameSource]}
          </a>
        </Box>
      }
    </>
  )
}

