import React, {useState, useEffect} from 'react';

import {
  createFilterOptions,
  Autocomplete,
  TextField,
} from '@mui/material';

import {
  useGetList,
  Labeled,
  useInput,
  InputProps
} from 'react-admin';

import { DatastorePagination } from '../../services';
import {TranslationKey} from '../../models';

export const TranslationKeyInput = (props: {source: string, label: string}) => {

  const {source, label} = props;
  const keyInput = useInput({source: source} as InputProps<string>)
  const {data, isLoading} = useGetList<TranslationKey>('TranslationKey', {pagination:DatastorePagination.All});

  const [selectedKey, setSelectedKey] = useState<TranslationKey | null>(null);

  useEffect(() => {
    if (data) {
      const currentKeyValue = keyInput.field.value;
      const translationKeys = data.filter(tk => tk.key === keyInput.field.value)

      if (translationKeys.length === 0 && currentKeyValue !== '') {
        keyInput.field.onChange('');
        setSelectedKey(null);
      }
      else if (translationKeys.length > 0) setSelectedKey(translationKeys[0]);
      else setSelectedKey(null);

    }
  }, [data,keyInput])

  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options:Array<TranslationKey>, state:any):Array<TranslationKey> => {
    return (defaultFilterOptions(options, state) as Array<TranslationKey>)
     .slice(0, 100) as Array<TranslationKey>;
  }

  const updateRecord = (translationKey: TranslationKey | null) => {
    keyInput.field.onChange(translationKey?.key ?? '');
  }

  const isOptionEqualToValue = (option:TranslationKey, value:TranslationKey):boolean =>{
    if (!option || !value) return false;
    return option.id === value.id;
  }

  return (
    <Labeled>
        <Autocomplete
          sx={{minWidth:'300px'}}
          options={data ?? []}
          value={selectedKey}
          filterOptions={filterOptions}
          isOptionEqualToValue={isOptionEqualToValue}
          loading={isLoading}
          getOptionLabel={(option) => option?.key ?? ''}
          onChange={(event,value,reason,details) => {updateRecord(value)}}
          renderInput={(params) =>
            <TextField
              multiline
              placeholder={'search key...'}
              variant='standard'
              {...params}
              label={label}
            />}
        />
    </Labeled>
  );
}

