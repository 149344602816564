import React from "react";

import {
  TopToolbar,
  CreateButton,
  List,
  ListProps,
  PublicFieldProps,
  Datagrid,
  DateField,
  TextField,
  DeleteButton,
  useRecordContext,
  useListContext,
  useUpdate,
} from 'react-admin';

import { Switch } from "@mui/material";
import { Language } from "../../models";

const RemoveLanguageButton = (props:PublicFieldProps) => {
  const record = useRecordContext<Language>();
  const confirmContent = 'Are you sure you want to delete this language? All translations for this language will be deleted.';

  return (
    <DeleteButton
      {...props}
      disabled={record.published ?? true}
      label='Delete'
      mutationMode='pessimistic'
      confirmTitle={`Delete ${record.displayName ?? 'this'} language?`}
      confirmContent={confirmContent}
    />
  )
}

const PublishLanguageSwitch = (props:PublicFieldProps) => {
  const {resource} = useListContext();
  const record = useRecordContext<Language>();
  const [update, {isLoading}] = useUpdate(resource);

  const toggleLanguagePublished = (event:any) => {
    event.stopPropagation()
    update(resource,{
        id: record.id,
        data: {published: !record.published},
        previousData: record,
    })
  }

  return (
    <Switch
      checked={record.published ?? false}
      onClick={toggleLanguagePublished}
      disabled={isLoading}
    />
  )
}

const LanguageActions= () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='add language'/>
    </TopToolbar>
  );
}

export const LanguageList = (props:ListProps) => {

  return (
    <List {...props}
      exporter={false}
      actions={<LanguageActions/>}
      sort={{ field: 'displayName', order: 'ASC' }}
    >
      <Datagrid
        hover={false}
        bulkActionButtons={false}
      >
        <TextField source="displayName" label="Name" />
        <TextField source="nativeName" label="Native Name" />
        <TextField source="locale" />
        <PublishLanguageSwitch label="published"/>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <RemoveLanguageButton />
      </Datagrid>
    </List >
  )
};
