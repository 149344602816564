import { makeStyles } from "./makeStyle";

export const useCustomFormStyles = makeStyles({ name: 'CustomForms' })(
  (theme)=>({

  localeSelection: {
    margin: "8px 0px 4px",
    minWidth: "140px",
  },
  formControl: {
    margin: "8px 0px 4px",
    minWidth: "256px",
  },
  //selectEmpty: {
    //marginTop: theme.spacing![2]
  //},
  title: {
    margin: "8px"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  field: {
    display: "block",
    padding: "4px 0 8px"
  },
  playlistContainer:
  {
    padding: "8px 0 8px",
  },
  image:{
    maxHeight: "3em",
    maxWidth: "5em",
  },
  thumbnail:{
    width: "5em",
    display: "flex",
    padding: "12px 24px"
  },
  combinedControl:{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px 0px 16px 0px"
  },
  addOption:{
    display: "flex",
    flexDirection: "row",
  },
  optionIcon:{
    color: "rgba(0, 0, 0, 0.54)"
  },
  fullwidth:{
    width: "100%"
  },
  badgeChip:{
    margin:"4px 8px 4px 0px",
    color:"primary",
  },
  disabled:{
    filter: "opacity(40%)"
  },
  //removeButton: {
    //color: theme.palette?.error
  //}
}));

