
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  ProviderProps
} from 'react';

import {useGetList} from 'react-admin';
import {DatastorePagination} from '../../services';
import {ImageFile, VideoFile} from '../../models';

import _ from 'lodash';

export interface MediaItemInfo {
  id: string
  thumbnailKey: string
  filename: string
  type: 'image' | 'video'
  localisations: string[]
  protected: boolean
}

export type ImageFileInfo =
  Omit<ImageFile, 'languages' | 'playlists'> &
  {
    languages: string[],
    playlists: string[],
  }

export type VideoFileInfo = 
  Omit<VideoFile, 'languages' | 'playlists'> &
  {
    languages: string[],
    playlists: string[],
  }


export const MediaLookupContext =
  createContext<[loading:boolean, lookup:Map<string, MediaItemInfo>]>([true,new Map<string, MediaItemInfo>()])

export const MediaLookupProvider =
  (props: Omit<ProviderProps<[loading: boolean, lookup: Map<string, MediaItemInfo>]>, 'value'>) =>
{
  const value = useMediaLookup();
  return <MediaLookupContext.Provider value={value} {...props}> {props.children} </MediaLookupContext.Provider>
}

export const useMediaLookupContext = () => useContext(MediaLookupContext);

export const useMediaLookup = (): [loading: boolean, lookup: Map<string, MediaItemInfo>] => {

  const [mediaLookup, setMediaLookup] = useState(new Map<string,MediaItemInfo>()); // mapping all existing videos and images to their id
  const [isLoading, setIsLoading] = useState(true);

  const {data: images, isLoading: imagesLoading} = useGetList('ImageFile', {pagination: DatastorePagination.All});
  const {data: videos, isLoading: videosLoading} = useGetList('VideoFile', {pagination: DatastorePagination.All});

  useEffect(() => {
    if ( !imagesLoading && !videosLoading) {
      const lookup = buildMediaLookup(_.values(images), _.values(videos));
      setMediaLookup(lookup);
      setIsLoading(false);
    }
    else
    {
      setIsLoading(true);
    }
  }, [images, videos, imagesLoading, videosLoading]);

  return [isLoading, mediaLookup];

}

const buildMediaLookup = (imageFiles:Array<ImageFileInfo>, videoFiles:Array<VideoFileInfo>):Map<string,MediaItemInfo> => {
  const lookup = new Map<string,MediaItemInfo>();
  imageFiles.forEach(img => lookup
    .set(img.id,
      {
        id:img.id,
        thumbnailKey:img.imageStorageKey,
        filename: img.imageFileName,
        type:'image',
        localisations: img.languages,
        protected: img.protected === true
      })
    );
  videoFiles.forEach(vdo => lookup
    .set(vdo.id,
      {
        id:vdo.id,
        thumbnailKey:vdo.posterStorageKey,
        filename: vdo.videoFileName,
        type:'video',
        localisations: vdo.languages,
        protected: vdo.protected === true
      })
    );
  return lookup;
}
