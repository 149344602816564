
import React, {useState, useEffect} from 'react';

import {
  useInput,
  useNotify,
  LinearProgress,
  Labeled
} from 'react-admin';

import {useWatch} from 'react-hook-form'

import {Button, Box,Divider, ListItem, List,Typography, Stack, Chip} from '@mui/material';
import { usePresignedUrl, useUrlCacheContext } from '../../components';

import { List as DragAndDropContainer, arrayMove, OnChangeMeta} from 'react-movable';
import {useMediaLookupContext, MediaItemInfo} from './mediaLookup';
import { useLanguageLookupContext } from '../../components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ImageIcon from '@mui/icons-material/Image';
import TheatersIcon from '@mui/icons-material/Theaters';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';



const sanitizeSequence = (sequence:Array<string>, mediaLookup:Map<string,MediaItemInfo>) => {
  const sanitizedSequence = sequence.filter(id => mediaLookup.get(id));
  const updated = sanitizedSequence.length !== sequence.length;
  return [updated, sanitizedSequence];
}

export const MediaSequenceInput = () => {
  const input = useInput({source: 'sequence'});
  const mediaItemIds = useWatch({name: 'sequence', defaultValue:[]});
  const notify = useNotify();

  const [lookupIsLoading, lookup] = useMediaLookupContext();

  useEffect(() => {
    if (mediaItemIds === null) input.field.onChange([]);
    if (mediaItemIds !== null && mediaItemIds.length > 0 && !lookupIsLoading) {

      const [updated, sanitizedSequence] = sanitizeSequence(input.field.value, lookup);
      if (updated) {
        input.field.onChange(sanitizedSequence);
        notify("Videos or images were deleted. The playlist has been updated.", {type: 'info'});
      }
    }
  }, [lookupIsLoading,lookup,mediaItemIds]);

  const updateSequence = ({oldIndex, newIndex}:OnChangeMeta) => {
    const currentSequence = input.field.value as Array<string>;
    const updatedSequence = arrayMove(currentSequence, oldIndex, newIndex);
    input.field.onChange(updatedSequence);
  }

  return (
    (mediaItemIds !== null && mediaItemIds.length) > 0 ?
    <Labeled label='Playlist' fullWidth>
      <>
        {!lookupIsLoading ?
          <DragAndDropContainer
            lockVertically
            values={mediaItemIds}
            onChange={updateSequence}
            renderList={({children, props}) => <List {...props}>{children}</List>}
            renderItem={({value, props, isDragged, index}) =>
              <ListItem sx={{zIndex: '100'}} {...props} disablePadding>
                <MediaSequenceItem
                  isDragged={isDragged}
                  index={index}
                  mediaItemId={value}
                  length={mediaItemIds.length}
                />
              </ListItem>
            }
          /> :
          <LinearProgress sx={{width: '100%'}} />
        }
      </>
      </Labeled> :
      null
  );
}

interface MediaSequenceItemProps {
  mediaItemId:string,
  index: number | undefined,
  length: number,
  isDragged:boolean
}

const MediaSequenceItem = (props: MediaSequenceItemProps) => {
  const [mediaLookupLoading, mediaLookup]= useMediaLookupContext();
  const [mediaItem, setMediaItem] = useState<MediaItemInfo | undefined>();

  const {index, isDragged, length} = props;

  useEffect(() => {
    const {mediaItemId} = props;
    if (!mediaLookupLoading && mediaLookup && mediaLookup.has(mediaItemId)) {
      setMediaItem(mediaLookup.get(mediaItemId));
    }
  },[mediaLookupLoading,mediaLookup,props])

  return (
    <Box key={index} width='100%' bgcolor='background.paper'>
      <Divider />
      <Stack direction='row' paddingY='7px' gap='15px' sx={{alignItems:'center'}}>
        <DragIndicatorIcon sx={{color:'grey.500'}}/>
        <MediaThumbnail width='15%' item={mediaItem}/>
        <MediaAccess width='10%' item={mediaItem}/>
        <MediaName width='30%' item={mediaItem}/>
        <MediaLocalisation width='30%' item={mediaItem}/>
        <Box flexGrow='1' display='flex' justifyContent='flex-end'>
          <RemoveMediaButton item={mediaItem}/>
        </Box>
      </Stack>
      {(isDragged || index === length-1) &&  <Divider />}
    </Box>
  );
}

const MediaThumbnail = (props:{item:MediaItemInfo|undefined, width:string})=> {
  const urlCache = useUrlCacheContext();
  const {item, width} = props;
  const [thumnailLoading, url, error] = usePresignedUrl(item?.thumbnailKey,undefined,urlCache);
  return (
    <Box width={width} maxHeight='50px' alignItems='center' >
      {!thumnailLoading && !error && <Box component='img' src={url} sx={{maxHeight: '50px', width: 'auto', maxWidth: '100%'}} />}
    </Box>
  );
}

const MediaName = (props:{item:MediaItemInfo|undefined, width:string}) => {
  const {item,width} = props;
  return (
    <Stack direction='row' gap='15px' sx={{width:{width}}}>
      {item && item.type === 'video' && <TheatersIcon />}
      {item && item.type === 'image' && <ImageIcon/>}
      <Typography sx={{overflow:'hidden', textOverflow:'ellipsis'}}>{item?.filename}</Typography>
    </Stack>
  );
}

const MediaAccess = (props: {width: string, item:MediaItemInfo|undefined}) => {
  const {width, item} = props;
  return (
    <Box width={width} justifyContent='center' display='flex'>
      {item && (item!.protected ? <HttpsIcon /> : <LockOpenIcon />)}
    </Box>
  );
}

const MediaLocalisation = (props:{item:MediaItemInfo|undefined, width:string}) => {
  const [languageLookupLoading, languageLookup]= useLanguageLookupContext()

  const {item, width} = props;
   return(<Box sx={{width:{width}}}>
      {!languageLookupLoading && item?.localisations &&
        item.localisations
          .map(lid => languageLookup.get(lid)?.displayName)
          .sort()
          .map(name =>
            <Chip key={name} label={name} variant='outlined' sx={{margin:'2px'}} />)
      }
    </Box>)
}

const RemoveMediaButton = (props: {item: MediaItemInfo|undefined}) => {
  const {item} = props;
  const sequenceInput= useInput({source: 'sequence'})
  const imageListInput = useInput({source: 'imageFiles'})
  const videoListInput = useInput({source: 'videoFiles'})

  const removeItem= () => {
    if (!item) return;

    const updatedSequence = (sequenceInput.field.value as Array<string>).filter(id => id !== item.id);
    sequenceInput.field.onChange(updatedSequence);

    if(item!.type === 'video'){
      const updatedVideoList = (videoListInput.field.value as Array<string>).filter(id => id !== item.id);
      videoListInput.field.onChange(updatedVideoList);
    }

    if(item!.type === 'image'){
      const updatedImageList = (imageListInput.field.value as Array<string>).filter(id => id !== item.id);
      imageListInput.field.onChange(updatedImageList);
    }
  }

  return (
    <Button sx={{minWidth:'100px'}} startIcon={<RemoveCircleIcon/>} onClick={removeItem}>Remove</Button>
  );

}

