import  _ from 'lodash';
import {schema} from '../../../models/schema';

const AssociationConnectionTypes = {
    HAS_MANY:'HAS_MANY',
    BELONGS_TO:'BELONGS_TO'
}

/**
 * @param {*} schema 
 * @returns 
 * An object of all join types e.g. Badge <-> BadgeLanguage <-> Language
 * Each key of the object is the name of the join type. Each value describes
 * which types the connection object contains and their field names:
 * 
 * {
 *    <JoinType>: {
 *      <ObjectType>: <ObjectKey>
 *    }
 * }
 * 
 * e.g.
 * {
 *    BadgeLanguage: {
 *      Badge: 'badge'
 *      Language: 'language'
 *    }
 * }
 */
const getJoinTypes = (schema) => {
  const isJoinModel = (model) => {
    if (_.values(model.fields).length !== 5) return false
    const { id, createdAt, updatedAt, ...connectionFields } = model.fields
    return (
      id &&
      createdAt &&
      updatedAt &&
      _.values(connectionFields).length === 2 &&
      _.values(connectionFields).every(
        (cf) =>
          cf.type.model &&
          cf.isRequired &&
          cf.association.connectionType ===
            AssociationConnectionTypes.BELONGS_TO
      )
    )
  }

  const getTypes = (model) => {
    const { id, createdAt, updatedAt, ...connectionFields } = model.fields
    return _.transform(
      connectionFields,
      (acc, value) => (acc[value.type.model] = value.name),
      {}
    )
  }

  const { models } = schema
  const joinModels = _.values(models).filter((m) => isJoinModel(m))
  return Object.fromEntries(joinModels.map((m) => [m.name, getTypes(m)]))
}


/**
 * 
 * @param {*} schema 
 * @returns An object with the nested objects of every model.
 * Each key refers to a Type which has a list of objects by a many-to-many relation.
 * Each value is an object describing the connections of the object.
 * The key of every description is the name of the field.
 * The description contains the Type of the join model as well as the name of both objects
 * in the join model.
 * 
 * {
 *  <TypeName> : {
 *    <ListFieldName> : 
 *    {
 *      resource: <ConnectionTypeName>
 *      self: <ConnectionFieldName>
 *      other: <ConnectionFieldName> 
 *    }
 *  }
 * }
 * 
 * e.g.
 * {
 *   Badge: {
 *      languages:{
 *          resource: 'BadgeLanguage'
 *          self: 'badge'
 *          other: 'language'
 *      }  
 *   },
 *   Language: {
 *      badges:{
 *          resource: 'BadgeLanguage'
 *          self: 'language'
 *          other: 'badge'
 *      },
 *      ...
 *   }
 * }
 */
const getJoinReferenceMap = (schema) => {
  const joinTypes = getJoinTypes(schema)
  const { models } = schema
  return _.transform(
    models,
    (typeAcc, typeValue) => {
      const { fields } = typeValue
      const fieldReferenceMap = _.transform(
        fields,
        (fieldAcc, fieldValue) => {
          const joinType = joinTypes[fieldValue.type.model]
          if (joinType) {
            const type = fieldValue.type.model
            const refs = _.mapKeys(joinType, (value, key) =>
              key === typeValue.name ? 'self' : 'other'
            )
            fieldAcc[fieldValue.name] = { resource: type, ...refs }
          }
        },
        {}
      )
      if (!_.isEmpty(fieldReferenceMap))
        typeAcc[typeValue.name] = fieldReferenceMap
    },
    {}
  )
}

export const ConnectionTypes = getJoinTypes(schema)
export const ConnectionMap = getJoinReferenceMap(schema)

