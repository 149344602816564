import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import {useHistory} from '../../utils';

export const useHeuristicReturnPath = (origins:Array<string>, defaultPath?:string) => {

  const [returnPath, setReturnPath] = useState<string|undefined>(defaultPath);

  const history = useHistory();
  const location = history[0];

  useEffect(() => {

    const originRx = origins
      .map(o => new RegExp(o.replace('{id}', '[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}') + '$'));

    const navActions =
      history.reduce(
        (acc, item, i, arr) => {
          if (arr[i + 1]) acc.push({ destination: item, source: arr[i + 1] });
          return acc;
        },
        [] as Array<{destination:string, source:string}>
      );

    const returnPaths = navActions
      .filter(n => n.destination === location)
      .filter(n => originRx.some(rx => n.source.match(rx)))
      .map(n => n.source);

    if (_.isEmpty(returnPaths)) setReturnPath(defaultPath);
    else setReturnPath(returnPaths[0])

  }, [history, location, defaultPath, origins])

  return returnPath;
}
