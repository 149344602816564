
export type UserStatus =
  'UNCONFIRMED' |
  'CONFIRMED' |
  'ARCHIVED' |
  'UNKNOWN' |
  'RESET_REQUIRED' |
  'FORCE_CHANGE_PASSWORD';

export type UserGroupName = 'Admin' | 'Team' | 'Customer'; 
export type SignUpLocation = 'CMS' | 'SHOWROOM';

export interface ApiUserType {
  id: string,
  enabled:boolean,
  email: string,
  emailConfirmed: boolean,
  createdAt: Date,
  updatedAt: Date,
  status: UserStatus,
  groups: Array<UserGroupName>,
  signUpLocation: SignUpLocation
}

export type User= Omit<ApiUserType, 'groups'> & {group: UserGroupName};

const GroupPrecedence: {[k: string]: number} = {
  'Admin': 0,
  'Team': 1,
  'Customer':2
}

export const toUser = (user:ApiUserType): User => {

  const {groups, ...partialUser} = user;

  const group = groups.length > 0
    ? groups.reduce((previous: UserGroupName, current: UserGroupName) => {
        return GroupPrecedence[previous] < GroupPrecedence[current] ? previous : current;
      })
    : 'Customer';

  return {
    ...partialUser,
    group: group
  };
}

export const toApiUserType = (user: User): ApiUserType => {

  const {group, ...partialUser} = user;
  return {
    ...partialUser,
    groups:[group]
  }

}
