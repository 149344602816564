
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
  RaRecord,
  DataProvider,
  CreateParams,
  CreateResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "ra-core";


import { ApiGroupType, toGroup} from './userGroupType';
import _ from "lodash";
import { ApiClient } from "./apiClient";
import {GetListGroupResponse} from "./groupQueries";

export class UserGroupsDataProvider implements DataProvider<string>{

  public async getList<RecordType extends RaRecord>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>>
  {
    const path = '/groups';
    const {groups} = (await ApiClient.get(path, {})) as GetListGroupResponse;
    const localGroups = groups.map(g => toGroup(g as ApiGroupType));

    return {
      data: localGroups as any,
      total: localGroups.length
    };
  }

  public async getOne<RecordType extends RaRecord>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>>
  {
    const {id} = params;
    const path = `/groups/${id}`;
    const group = (await ApiClient.get(path, {})) as ApiGroupType;
    const localGroup = toGroup(group);
    return localGroup as any;
  }

  public async getMany<RecordType extends RaRecord>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>>
  {
    const {ids} = params;
    const localGroups = [];
    for (const id of ids) {
      const path = `/groups/${id}`;
      const group = (await ApiClient.get(path, {})) as ApiGroupType;
      const localGroup = toGroup(group);
      localGroups.push(localGroup);
    }
    return {
      data: localGroups as any[]
    }
  }

  public getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ):Promise<GetManyReferenceResult<RecordType>>
  {
    throw new Error('Method not implemented');
  }

  public update<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>>
  {
    throw new Error('Method not implemented')
  }

  public updateMany<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateManyParams<any>
  ): Promise<UpdateManyResult<RecordType>>
  {
    throw new Error('Method not implemented')
  }

  public async create<RecordType extends RaRecord = any>(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>>
  {
    throw new Error('Method not implemented');
  }

  public async delete<RecordType extends RaRecord>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>>
  {
    throw new Error('Method not implemented');
  }

  public async deleteMany<RecordType extends RaRecord>(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult<RecordType>>
  {
    throw new Error('Method not implemented');
  }

}
