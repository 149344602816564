export const EnrxLogoBlack = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 280 53.54"
      >
      <g id="ENRX-logo-black" transform="translate(-13 -72.1)">
        <path id="Path_10722" data-name="Path 10722" d="M137.5,72.2a3.346,3.346,0,0,0-2.9,3.3l-.1,39.5L92.7,73.1a3.332,3.332,0,0,0-4.3-.3,3.225,3.225,0,0,0-1.2,2.6v47.2a3.271,3.271,0,0,0,2.9,3.3h.3a3.5,3.5,0,0,0,2.2-.8,3.194,3.194,0,0,0,1.1-2.4l.1-39.6L135.7,125a3.182,3.182,0,0,0,3.8.5,3.488,3.488,0,0,0,1.7-3V75.4a3.194,3.194,0,0,0-1.1-2.4A3.66,3.66,0,0,0,137.5,72.2Z"/>
        <path id="Path_10723" data-name="Path 10723" d="M226.7,73.1a3.114,3.114,0,0,0-2.6-.9,3.168,3.168,0,0,0-1.9,5.5l20.9,20.9a.561.561,0,0,1,0,.9l-21,21a3.2,3.2,0,0,0,0,4.6,3.388,3.388,0,0,0,4.6,0l21-21a.561.561,0,0,1,.9,0l21,21a3.389,3.389,0,0,0,4.6,0,3.2,3.2,0,0,0,0-4.6Z"/>
        <path id="Path_10724" data-name="Path 10724" d="M257.7,93.6a3.168,3.168,0,0,0,2.3-.9l15-15a3.2,3.2,0,0,0,0-4.6,3.388,3.388,0,0,0-4.6,0l-15,15a3.389,3.389,0,0,0,0,4.6A3.523,3.523,0,0,0,257.7,93.6Z"/>
        <path id="Path_10725" data-name="Path 10725" d="M21.4,90.3a3.159,3.159,0,0,0,3.2-3.2V82.2a3.359,3.359,0,0,1,3.2-3.5h41a3.2,3.2,0,0,0,0-6.4h-41a9.8,9.8,0,0,0-9.6,9.9V87A3.182,3.182,0,0,0,21.4,90.3Z"/>
        <path id="Path_10726" data-name="Path 10726" d="M68.8,119.6h-41a3.359,3.359,0,0,1-3.2-3.5V106a3.359,3.359,0,0,1,3.2-3.5H66.9a3.2,3.2,0,0,0,0-6.4H27.8a9.8,9.8,0,0,0-9.6,9.9v10.2a9.734,9.734,0,0,0,9.6,9.9h41a3.159,3.159,0,0,0,3.2-3.2A3.244,3.244,0,0,0,68.8,119.6Z"/>
        <path id="Path_10727" data-name="Path 10727" d="M209,120.4l-18.2-18.2a.367.367,0,0,1-.1-.3s.1-.2.2-.2h4.3a14.9,14.9,0,0,0,10.7-4.5A14.521,14.521,0,0,0,210,86.4a15.161,15.161,0,0,0-15.2-14.3H159.5a3.246,3.246,0,0,0-2.5,5.4,3.194,3.194,0,0,0,2.4,1.1h35.8a8.4,8.4,0,0,1,0,16.8H163.8a7.6,7.6,0,0,0-7.6,7.6v19.8a3.271,3.271,0,0,0,2.9,3.3h.3a3.5,3.5,0,0,0,2.2-.8,3.194,3.194,0,0,0,1.1-2.4V103a1.11,1.11,0,0,1,1.1-1.1h16.8a1.845,1.845,0,0,1,1.2.5l22.9,22.8a3.149,3.149,0,0,0,5.4-2.2A4.707,4.707,0,0,0,209,120.4Z"/>
        <g id="Group_6307" data-name="Group 6307">
          <path id="Path_10728" data-name="Path 10728" d="M286.2,77.2l-1.3-1.3h.3a.908.908,0,0,0,.7-.3,1.135,1.135,0,0,0,.3-.8,1.028,1.028,0,0,0-1.1-1h-1.8a.215.215,0,0,0-.2.2h0v3.4a.215.215,0,0,0,.2.2h0c.1,0,.1,0,.2-.1a.349.349,0,0,0,.1-.2V75.9a.1.1,0,0,1,.1-.1h.5l1.6,1.6a.187.187,0,0,0,.3,0,.349.349,0,0,0,.1-.2C286.3,77.3,286.2,77.2,286.2,77.2Zm-2.6-1.9v-1a.1.1,0,0,1,.1-.1h1.5a.56.56,0,0,1,.6.5c0,.4-.2.7-.6.7l-1.6-.1C283.7,75.4,283.6,75.4,283.6,75.3Z"/>
          <g id="Group_6306" data-name="Group 6306">
            <path id="Path_10729" data-name="Path 10729" d="M284.7,79.1a3.5,3.5,0,1,1,3.5-3.5A3.543,3.543,0,0,1,284.7,79.1Zm0-6.4a3,3,0,1,0,3,3A3.009,3.009,0,0,0,284.7,72.7Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
