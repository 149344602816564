
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  ProviderProps
} from 'react';

import {useGetList} from 'react-admin';
import { DatastorePagination } from '../../services';
import {DocumentFile} from '../../models';

export type DocumentFileInfo = Omit<DocumentFile, 'languages' | 'contentPages'> & {languages:[string], contentPages:[string]}

export const DocumentLookupContext =
  createContext<[loading:boolean, lookup:Map<string,DocumentFileInfo>]>([true,new Map<string,DocumentFileInfo>()])

export const DocumentLookupProvider =
  (props: Omit<ProviderProps<[loading: boolean, lookup: Map<string, DocumentFileInfo>]>, 'value'>) =>
{
  const value = useDocumentLookup();
  return <DocumentLookupContext.Provider value={value} {...props}> {props.children} </DocumentLookupContext.Provider>
}

export const useDocumentLookupContext = () => useContext(DocumentLookupContext);

export const useDocumentLookup = (): [loading: boolean, lookup: Map<string, DocumentFileInfo>] => {

  const [documentLookup, setDocumentLookup] = useState(new Map<string, DocumentFileInfo>());
  const [isLoading, setIsLoading] = useState(true);

  const {data: documents, isLoading:documentsLoading} = useGetList('DocumentFile', {pagination:DatastorePagination.All})

  useEffect(() => {
    if ( documents && !documentsLoading) {
      const lookup = new Map(documents.map(l => [l.id, l]));
      setDocumentLookup(lookup);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [documents,isLoading]);

  return [isLoading,documentLookup];
}

