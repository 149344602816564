import React from 'react';
import {Box, Typography} from '@mui/material';
import ReactMarkdown from 'react-markdown';

import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

export const Markdown = (props: any) => {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      components={{
        h2: ({ node, ...props }) => (
          <Typography component={'span'} variant="h4" gutterBottom {...props} />
        ),
        h3: ({ node, ...props }) => (
          <Typography component={'span'} variant="h5" gutterBottom {...props} />
        ),
        h4: ({ node, ...props }) => (
          <Typography component={'span'} variant="h6" gutterBottom {...props} />
        ),
        p: ({ node, ...props }) => (
          <Typography component={'span'} variant="body2" gutterBottom {...props} />
        ),
        br: ({node}) => <Box component={'div'} height="1.5rem" />,
      }}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
    >
      {props.children}
    </ReactMarkdown>
  )
}


