import React from "react";
import {Typography} from '@mui/material';

import { 
  useRecordContext,
  PublicFieldProps,
  LinearProgress
} from 'react-admin';

import { useDetailPanelStyles } from '../../theme';
import { usePresignedUrl } from '../storage/usePresignedUrl';
import ReactPlayer from 'react-player';

import _ from 'lodash';

export const DetailsVideoField = (props:PublicFieldProps) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  const {source} = props;

  const [loading, url, error] = usePresignedUrl(_.get(record,source ?? '',undefined));

  return (
    <>
      <Typography
        className={styles.classes.label}
        variant="caption"
      >
        {props.label}
      </Typography>

      {(loading || error) ?
        <LinearProgress sx={{width:'100%'}} />
        :
        <VideoPlayer videoUrl={url!}/>
      }
    </>
  )
}

export const VideoPlayer = (props:{videoUrl:string}) => {
  const styles = useDetailPanelStyles();
  const {videoUrl} = props;

  return (
    <div className={styles.classes.video}>
        <ReactPlayer width="100%" height="100%" url={videoUrl} controls={true} />
    </div>
  )
}
