import { ReferenceField } from "react-admin";
import { DetailsPanel, DetailsDateField, DetailsTextField } from "../../components";

export const TranslationDetails = () => {
  return (
    <DetailsPanel size='small'>
      <ReferenceField
        source="languageID"
        reference="Language"
        link={false}
      >
        <DetailsTextField label="Language" source="displayName"/>
      </ReferenceField>
      <DetailsDateField source='createdAt' label='Created At'/>
      <DetailsDateField source='updatedAt' label='Updated At'/>
    </DetailsPanel>
  )
}
