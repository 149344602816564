import React, {ReactNode} from "react";
import {Card, CardContent} from '@mui/material';
import {useDetailPanelStyles} from '../../theme';

export interface DetailPanelProps  {
  size: 'small' | 'large',
  children: ReactNode
} 

export const DetailsPanel = (props:DetailPanelProps) => {

  const styles = useDetailPanelStyles();

  return (
    <Card className={props.size === 'large' ?  styles.classes.asideLarge : styles.classes.aside}>
      <CardContent>
        {props.children}
      </CardContent>
    </Card>
  )
}

