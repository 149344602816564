import React from 'react'

import {Admin, Resource, CustomRoutes} from 'react-admin';
import {ReactAdminTheme} from '../../theme';
import { AppLayout } from './layout';
import {AuthAdapter, dataProvider, GroupName} from '../../services';
import { Route } from 'react-router-dom';
import { HistoryProvider } from '../../utils';
import { GroupNames } from '../../services';

import {
  AuthPage,
  LanguageList,
  LanguageCreate,
  TranslationKeyList,
  TranslationKeyCreate,
  TranslationKeysShow,
  TranslationCreate,
  TranslationEdit,
  UserList,
  ImageFileCreate,
  ImageFileList,
  ImageFileEdit,
  VideoFileList,
  VideoFileEdit,
  VideoFileCreate,
  DocumentFileList,
  DocumentFileEdit,
  DocumentFileCreate,
  DebugPage,
  PlaylistList,
  PlaylistCreate,
  PlaylistEdit,
  ContentPageList,
  ContentPageEdit,
  ContentPageCreate
} from '../../views';


export const AdminRoot = () => {

  return (
    <HistoryProvider>
      <Admin
        loginPage={AuthPage}
        authProvider={AuthAdapter}
        dataProvider={dataProvider}
        theme={ReactAdminTheme}
        layout={AppLayout}
        requireAuth
      >
        {(permissions) => (
          <>
            {permissions.includes(GroupNames.Admin) && (
              <>
                <Resource
                  name="ContentPage"
                  create={ContentPageCreate}
                  list={ContentPageList}
                  edit={ContentPageEdit}
                />

                <Resource name="ContentPagePlaylist" />

                <Resource
                  name="Playlist"
                  create={PlaylistCreate}
                  list={PlaylistList}
                  edit={PlaylistEdit}
                />

                <Resource name="PlaylistImageFile" />

                <Resource name="PlaylistVideoFile" />

                <Resource
                  name="DocumentFile"
                  create={DocumentFileCreate}
                  list={DocumentFileList}
                  edit={DocumentFileEdit}
                />

                <Resource name="DocumentFileLanguage" />

                <Resource
                  name="VideoFile"
                  create={VideoFileCreate}
                  list={VideoFileList}
                  edit={VideoFileEdit}
                />

                <Resource name="VideoFileLanguage" />

                <Resource
                  name="ImageFile"
                  create={ImageFileCreate}
                  list={ImageFileList}
                  edit={ImageFileEdit}
                />

                <Resource name="ImageFileLanguage" />

                <Resource
                  name="Translation"
                  create={TranslationCreate}
                  edit={TranslationEdit}
                />

                <Resource
                  name="TranslationKey"
                  list={TranslationKeyList}
                  create={TranslationKeyCreate}
                  show={TranslationKeysShow}
                />

                <Resource
                  name="Language"
                  list={LanguageList}
                  create={LanguageCreate}
                />
              </>
            )}
            {(permissions as Array<GroupName>).some(p => [GroupNames.Admin, GroupNames.Team].includes(p)) &&
              (
              <>
                <Resource name="users" list={UserList} />
                <Resource name="groups" />
              </>
            )}

            <CustomRoutes>
              <Route path="/debug" element={<DebugPage />} />
            </CustomRoutes>
          </>
        )}
      </Admin>
    </HistoryProvider>
  )
}

