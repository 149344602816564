// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { TranslationKey, Language, Translation, ImageFile, VideoFile, DocumentFile, Playlist, ContentPage, ImageFileLanguage, VideoFileLanguage, DocumentFileLanguage, PlaylistImageFile, PlaylistVideoFile, ContentPageDocumentFile, ContentPagePlaylist } = initSchema(schema);

export {
  TranslationKey,
  Language,
  Translation,
  ImageFile,
  VideoFile,
  DocumentFile,
  Playlist,
  ContentPage,
  ImageFileLanguage,
  VideoFileLanguage,
  DocumentFileLanguage,
  PlaylistImageFile,
  PlaylistVideoFile,
  ContentPageDocumentFile,
  ContentPagePlaylist
};