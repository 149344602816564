
import {
  DetailsPanel,
  DetailsDateField,
  DetailsImageField,
  DetailsVideoField,
  DetailsTextField,
  DetailsBooleanField
} from '../../components'

import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const VideoFileDetails = () => {
  return (
    <DetailsPanel size="large">
      <DetailsImageField label="Poster" source="posterStorageKey" />
      <DetailsTextField label="Poster file name" source="posterFileName" />
      <DetailsVideoField label="Video" source="videoStorageKey"/>
      <DetailsTextField label="Video file name" source="videoFileName" />
      <DetailsBooleanField
        label='Restricted acess'
        source='protected'
        TrueIcon={HttpsIcon}
        FalseIcon={LockOpenIcon}
      />
      <DetailsDateField label="Created at" source="createdAt" />
      <DetailsDateField label="Updated at" source="updatedAt" />
    </DetailsPanel>
  )
}
