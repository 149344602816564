import { Storage } from "aws-amplify";
import { PutResult, S3ProviderPutConfig } from '@aws-amplify/storage'
import { HttpError, RaRecord } from "react-admin";
import { attachStorageId } from "./utility";
import React, {RefObject} from "react";
import _ from 'lodash';

export interface FileUploadRequest {
  key: string,
  file: File,
  storageOptions: S3ProviderPutConfig,
  uploadProcessList?: RefObject<Promise<PutResult>[]>
}

export interface FileUploadProgress {
  [key: string]: any,
  loaded: number,
  total: number
}

const isUploadCancelledError = (error: any):boolean => {
  return (
    _.has(error, 'message') &&
    typeof error.message === typeof '' &&
    _.has(error, '$metadata') &&
    _.has(error, '$metadata.attempts') &&
    typeof error.$metadata.attempts === typeof 0 &&
    _.has(error, '$metadata.totalRetryDelay') &&
    typeof error.$metadata.totalRetryDelay === typeof 0
  );
}

const isMultipartUploadCancelledError = (error: any):boolean => {
  return (
    typeof error === typeof {} &&
    _.has(error, 'message') &&
    error.message.match(/Upload was cancelled./) !== null
  )
}

export const uploadFile = async (uploadRequest: FileUploadRequest): Promise<PutResult> => {

  const {key, file, storageOptions} = uploadRequest;
  try {
    const uploadHandle = Storage.put(key, file, storageOptions);
    if (uploadRequest.uploadProcessList) {
      uploadRequest.uploadProcessList.current?.push(uploadHandle);
    }
    const result = await uploadHandle;
    return result;
  }
  catch (error) {
    if (
      isUploadCancelledError(error) ||
      isMultipartUploadCancelledError(error)
    ) {
      throw new HttpError(`File upload cancelled.`, 400);
    }
    else {
      throw new HttpError(`Failed to upload ${file.name}`, 500);
    }
  }
}


/**
 * @property {'all'|'authenticated'} acess - who should be able to download the file
 * @property {string} sourceProperty - the property of the record containing the raw file
 * @property {string} destinationProperty - the property of the record which will contain the s3 key of the file
 * @property {string} filenameProperty - the property of the record which will contain the file name
 * @property {S3ProviderPutConfig} storageOptions - such as storage level or progress handler
 */
export interface RecordFileAttachment {
  sourceProperty: string,
  destinationProperty: string,
  filenameProperty: string,
  access?: 'all' | 'authenticated',
  storageOptions?: S3ProviderPutConfig
}

/**
 * Modifies the Record so that the datastoreAdapter will upload
 * the contained raw file and enters the resulting file key in the
 * apropriate record property
 *
 * @param {RaRecord} record - database record associated with the file
 * @param {Array<RecordFileAttachment>} attachments - metadata for the fileupload
 * @returns {RaRecord} a modified record containing uploadRequest in the _files property
 */
export const transformFileAttachment = (
  record:RaRecord,
  attachments:Array<RecordFileAttachment>,
  uploadProcessList?: RefObject<Promise<PutResult>[]>
):RaRecord =>
  {

  let { ...data } = record;
  let _files:Record<string,FileUploadRequest> = {};

  attachments.forEach((attachment) => {
    const {
      sourceProperty: source,
      destinationProperty: destination,
      filenameProperty: filename
    } = attachment;
    let {access, storageOptions} = attachment;

    if(!access) access = 'all';
    if(!storageOptions) storageOptions = {level:'public'}

    // this is required since, when a user adds
    // an image and then removes it from the dropzone
    // the pristine state of the form gets out of sync
    if (_.has(data,source) && _.isEmpty(data[source])) {
      delete data[source];
      return data;
    }

    const file = _.get(data, `${source}.rawFile`) as File;
    storageOptions.contentType = file.type;
    data[filename] = file.name;

    const key = [
      access === 'all' ? 'guest' : 'authenticated',
      attachStorageId(file.name)
    ].join('/');

    const uploadRequest = {key, file, storageOptions, uploadProcessList};

    _files[destination] = uploadRequest;
    delete data[source];
  });
  if (!_.isEmpty(_files)) data._files = _files;
  return data;
};


export const cancelFileUploads = (uploadProcessList: RefObject<Promise<PutResult>[]>) => {
  uploadProcessList.current?.forEach(upload => {
    Storage.cancel(upload, '(File upload cancelled by user.)');
  });
}
