import React from 'react';
import _ from 'lodash';
import { Typography} from '@mui/material';

import { useRecordContext } from 'ra-core';
import { useDetailPanelStyles } from '../../theme';
import { PublicFieldProps } from 'react-admin';

export const DetailsTextField = (props:PublicFieldProps) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  return (
    <>
      <Typography
        className={styles.classes.label}
        variant="caption"
      >
        {props.label}
      </Typography>
      <Typography
        className={styles.classes.field}
        variant="body2"
        noWrap={true}
      >
        { record ? _.get(record, props.source!) : ""}
      </Typography>
    </>
  )
}

