import React from 'react';

import {Box, Typography} from '@mui/material';
import TheatersIcon from '@mui/icons-material/Theaters';

import {useMediaDropFieldStyle} from '../../theme';
import { formatFloat } from '../../utils';

export const VideoPlaceholder = (props:{fileTypes:string[],maxFileSize:number}) => {
  const styles = useMediaDropFieldStyle()
  const {fileTypes,maxFileSize} = props;

  const formatConstraints = () =>{
    const acceptedTypes = fileTypes.join(', ');
    const acceptedSize = `${formatFloat((maxFileSize / 1e6),2)}Mb`
    return (`(${acceptedTypes}, max.: ${acceptedSize})`);
  }

  return (
    <Box className={styles.classes.Placeholder}>
      <div className={styles.classes.Icon}>
        <TheatersIcon fontSize="inherit" />
      </div>
      <Typography className={styles.classes.Text} variant="body2">
        Drop a video or click and select it.
      </Typography>
      <Typography className={styles.classes.Text} variant='caption'>
        {formatConstraints()}
      </Typography>
    </Box>
  )
}

