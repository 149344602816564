
import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import {
  useCreatePath,
  useGetList,
  LinearProgress,
  useRecordContext
} from 'react-admin';
import { DatastorePagination } from "../../services";
import { Box, Stack, Button} from "@mui/material";
import TranslateIcon from '@mui/icons-material/Translate';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import {TranslationKey } from "../../models";

export const ContentPageListExpand = () => {
  const record = useRecordContext();
  const {data: translationKeys, isLoading: keysLoading} = useGetList('TranslationKey', {pagination:DatastorePagination.All});
  const [translationLookup, setTranslationLookup] = useState(new Map<string,string>());

  useEffect(()=>{
    if(!keysLoading && translationKeys){
      const lookup = new Map((translationKeys as Array<TranslationKey>).map(k => [k.key, k.id]));
      setTranslationLookup(lookup);
    }
  }, [translationKeys,keysLoading])

  return (
    translationLookup.size > 0 ?
    <Stack direction='row' gap='10px'>
      <Box width='88px'/>
      <TranslationLink
        label='Title'
        id={translationLookup.get(record.titleTranslationKey) ?? null}
      />
      <TranslationLink
        label='Slogan'
        id={translationLookup.get(record.sloganTranslationKey) ?? null}
      />
      <TranslationLink
        label='Features'
        id={translationLookup.get(record.featuresTranslationKey) ?? null}
      />
      <TranslationLink
        label='Benefits'
        id={translationLookup.get(record.benefitsTranslationKey) ?? null}
      />
      <PlaylistLink
        label='Playlist'
        id={(record.playlists && record.playlists.length > 0) ? record.playlists[0] : null}
      />
    </Stack>
    :
    <LinearProgress sx={{width:'100%'}}/>
  );
}

const PlaylistLink = (props: {id: string | null, label: string}) => {

  const createPath = useCreatePath();
  const navigate = useNavigate();

  const {id,label} = props;

  const goTo = () => {
    if (id !== null) {
      const path = createPath({resource:'Playlist', type:'edit', id: id});
      navigate(path);
    }
  }

  return (
  <Button
    startIcon={<SlideshowIcon/>}
    disabled={id === null}
    onClick={goTo}
  >
    {label}
  </Button>
  );
}

const TranslationLink = (props: {id: string | null, label:string}) => {

  const createPath = useCreatePath();
  const navigate = useNavigate();

  const {id,label} = props;

  const goTo = () => {
    if (id !== null) {
      const path = createPath({resource:'TranslationKey', type:'show', id: id});
      navigate(path);
    }
  }

  return (
  <Button
    startIcon={<TranslateIcon/>}
    disabled={id === null}
    onClick={goTo}
  >
    {label}
  </Button>
  );
}

