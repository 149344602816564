
import React from "react";
import {
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  useRedirect,
} from 'react-admin';

import { Translation } from '../../models';
import { SaveCancelToolbar } from '../../components';
import { TranslationDetails } from './translationDetails';

const TranslationTitle = () => {
  return <span>
    Translation
  </span>;
};

export const TranslationEdit = (props:EditProps) => {
  const redirect = useRedirect();
  const onSuccess = (data: Translation) => redirect('show', '/TranslationKey', data.translationKeyID);

  return (
    <Edit
      {...props}
      title={<TranslationTitle />}
      mutationMode='pessimistic'
      mutationOptions={{onSuccess}}
      aside={<TranslationDetails />}
    >
      <SimpleForm toolbar={<SaveCancelToolbar />}>
        <TextInput
          fullWidth
          source="value"
          multiline={true}
          minRows="3"
          label='Translation'
          variant="standard"
        />
      </SimpleForm>
    </Edit>
  );
}



