
export interface ImageDimensions {
  width:number, height:number
}

export interface ImageValidationResult  {
  isValid: boolean,
  dimensions: ImageDimensions
}

export const getImageDimensions = (rawfile:File):Promise<ImageDimensions> => {
  return new Promise((resolve, reject) => {
    const url = window.URL.createObjectURL(rawfile);
    const image = new Image();
    image.onload = ev => {
      window.URL.revokeObjectURL(url);
      resolve({ width: image.width, height: image.height });
    };
    image.onerror = err => {
      window.URL.revokeObjectURL(url);
      reject(err)
    };
    image.src = url;
  });
}

export const maxImageDimensions = (maxDimensions:ImageDimensions) =>
  async (value: any | undefined, values: any): Promise<string | {message: string, args: any} | undefined> => {
    if(!value)return undefined;
    const {rawFile} = value;

    const imageDimensions = await getImageDimensions(rawFile);
    if (imageDimensions.width > maxDimensions.width || imageDimensions.height > maxDimensions.height) {
      return `Image exceeds max. dimensions: ${imageDimensions.width}x${imageDimensions.height}px (allowed max.: ${maxDimensions.width}x${maxDimensions.height}px)`
    }

    return undefined;
  }
;
