
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
  RaRecord,
  DataProvider,
  CreateParams,
  CreateResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "ra-core";

import {
  GetListUserResponse
} from './userQueries'

import { Pagination } from "./pagination";
import { User, toUser, toApiUserType} from './userType';
import _ from "lodash";
import { ApiClient } from "./apiClient";

export class UsersDataProvider implements DataProvider<string>{

  public async update<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<User>
  ): Promise<UpdateResult<RecordType>>
  {
    const {id, data:userUpdate} = params;

    const {group, ...rest} = userUpdate;
    const groups = group?[group]:[]
    const apiUserUpdate = {
      groups,
      ...rest
    }
    const path = `/users/${id!}`;
    const user = await ApiClient.put(path,apiUserUpdate);
    const localUser = toUser(user);

    return {data: localUser as any}
  }

  public async create<RecordType extends RaRecord = any>(
    resource: string,
    params: CreateParams<User>
  ): Promise<CreateResult<RecordType>>
  {

    const path = '/users';
    const user = toApiUserType(params.data);
    const newUser = await ApiClient.post(path, user as any);
    const newLocalUser = toUser(newUser);

    return {data: newLocalUser as any }
  }

  public async getList<RecordType extends RaRecord>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>>
  {
    const path = '/users';
    const { page, perPage } = params.pagination;
    const { filter, sort } = params;

    let filterString = '';
    if (_.keys(filter).length > 0) {
      const attribute = _.keys(filter)[0];
      const value = filter[attribute];
      filterString = `${attribute}^="${value}"`;
      console.log(filter, filterString);
    }

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      path,
      filter:filterString,
      sort,
      perPage,
    });

    const nextToken = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof nextToken === "undefined") {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    let queryResult: GetListUserResponse;

    // Executes the query
    queryResult = await ApiClient.get(path, {
      filter:filterString,
      sort,
      limit: perPage,
      nextToken,
    });

    // Remap to local user type
    const {users} = queryResult;
    const localUser = users.map(u => toUser(u));

    const responseNextToken = queryResult.nextToken || null;

    // Saves next token
    Pagination.saveNextToken(responseNextToken, querySignature, page);


    return {
      data: localUser as any[],
      pageInfo: {
        hasPreviousPage: page > 1,
        hasNextPage: responseNextToken !== null
      }
    };
  }

  public async getOne<RecordType extends RaRecord>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>>
  {
    const {id} = params;
    const path = `/users/${id}`;
    const user = await ApiClient.get(path, {});
    const localUser = toUser(user);

    return {
      data: localUser as any
    }
  }

  public async getMany<RecordType extends RaRecord>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>>
  {
    const {ids} = params;
    const users: User[] = [];

    for (const id of ids) {
      const path = `/users/${id}`;
      const user = await ApiClient.get(path, {});
      const localUser = toUser(user);
      users.push(localUser);
    }

    return { data: users as any[] }

  }

  public async delete<RecordType extends RaRecord>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>>
  {
    const {id} = params;
    const user = await this.getOne('',{id});
    const path = `/users/${id}`;
    await ApiClient.delete(path, {});
    return user as any;
  }


  public async deleteMany<RecordType extends RaRecord>(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult<RecordType>>
  {
    const {ids} = params;
    const deleted = [];

    for (const id of ids) {
      try {
        const path = `/users/${id}`;
        await ApiClient.delete(path, {});
        deleted.push(id);
      }
      catch (e) {
        console.error(e)
      }
    }

    return {data: deleted} ;
  }


  public getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ):Promise<GetManyReferenceResult<RecordType>>
  {
    throw new Error('Method not implemented');
  }

  public updateMany<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateManyParams<any>
  ): Promise<UpdateManyResult<RecordType>>
  {
    throw new Error('Method not implemented')
  }

}
