import { validatePassword } from "./passwords";
import { validateEmail } from "./emails";

export type InputValidationFunction = (input: string) => (boolean | string);
export interface InputValidator{
  password: InputValidationFunction;
  email: InputValidationFunction;
}

export const Validate: InputValidator = {
    password: validatePassword,
    email: validateEmail
}
