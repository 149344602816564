import React, {RefObject} from "react";

import { PutResult } from '@aws-amplify/storage'
import { ImageFile } from "../../models";
import { transformFileAttachment, RecordFileAttachment } from "../../services";
import { ImageDimensions } from "../../utils";

export const transformImageRecord = (record: ImageFile, uploadProcessList?: RefObject<Promise<PutResult>[]>) => {
    const attachment: RecordFileAttachment = {
      access: record.protected ? 'authenticated' : 'all',
      sourceProperty: '_imageStorageKey',
      filenameProperty: 'imageFileName',
      destinationProperty: 'imageStorageKey',
      storageOptions: {level: 'public'}
    };
    return transformFileAttachment(record, [attachment], uploadProcessList);
  }

export const MaxImageSize:number = 5000000; //5Mb
export const MaxImageDimensions:ImageDimensions = {width:1920, height: 1080};
