import { defaultTheme } from 'react-admin';
import { RaThemeOptions } from 'react-admin';

import {deepmerge} from '@mui/utils'
import {createTheme} from '@mui/material/styles'

// https://bareynol.github.io/mui-theme-creator/
const customTheme: RaThemeOptions = {
  palette: {
    primary: {
      main: '#37a46e',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#66cc99',
        }
      }
    }
  },
}


export const ReactAdminTheme:RaThemeOptions = createTheme(deepmerge(defaultTheme,customTheme))//merge({}, defaultTheme, customTheme);
