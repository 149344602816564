import React, {useState, useRef, useEffect} from 'react';

import { Storage } from "@aws-amplify/storage";
import { S3ProviderGetConfig} from '@aws-amplify/storage';
import { UrlCache } from './urlCache';

export const usePresignedUrl = (
  key: string | undefined ,
  storageOptions?: S3ProviderGetConfig,
  cache?:UrlCache
): [loading: boolean, url: string | undefined, error: any | undefined] => {

  const [url, setUrl] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any | undefined>(undefined)
  const mountedRef = useRef<boolean>(true)

  useEffect(() => {


    const getUrl = async (experationPeriod:number) => {
      if(key !== undefined){
        if (cache && cache.has(key, Date.now() - experationPeriod)) {
          setUrl(cache.get(key));
          setLoading(false);
          setError(undefined);
        }
        else {
          if (url === undefined) setLoading(true);

          try {
            const presignedUrl = await Storage.get(key, {level:'public', download: false });
            if(cache) cache.set(key, presignedUrl);

            if (mountedRef) { //in case the hook was unmounted during the async operation
              setUrl(presignedUrl);
              setError(undefined);
              setLoading(false);
            }
          } catch (error) {
            setUrl(undefined);
            setError(error);
            setLoading(false);
          }

        }
      }
      else {
        setLoading(true);
      }
    }

    let { expires = 900 } = storageOptions ?? {}
    const refreshPeriod = expires * 0.8 * 1000;
    const expirationPeriod = expires * 1000;
    const refreshPresignedUrl = setInterval(() => getUrl(expirationPeriod),refreshPeriod)

    getUrl(expirationPeriod);

    return () => {
      clearInterval(refreshPresignedUrl)
      mountedRef.current = false
    }
  }, [key,cache])

  return [loading, url, error]
}
