import React  from 'react';

import {
  required,
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  useRecordContext
} from 'react-admin';

import { SaveCancelToolbar, } from '../../components';
import { MediaSequenceInput } from './sequenceInput';
import { AddImageInput } from './addImageInput';
import { AddVideoInput } from './addVideoInput';
import { MediaLookupProvider } from './mediaLookup';
import { LanguageLookupProvider, UrlCacheProvider } from '../../components';
import { PlaylistDetails } from './playlistDetails';

import { Playlist } from '../../models';

const PlaylistTitle= () => {
  const record = useRecordContext<Playlist>()
  return <span>Playlist - { record ? record.name : '' }</span>
}

export const PlaylistEdit = (props: EditProps) => {

  return (
    <Edit {...props}
      redirect="list"
      aside={<PlaylistDetails/>}
      title={<PlaylistTitle/>}
      mutationMode='pessimistic'
    >
      <SimpleForm redirect="list" toolbar={<SaveCancelToolbar />}>
        <MediaLookupProvider>
          <LanguageLookupProvider>
            <UrlCacheProvider>
              <TextInput
                source="name"
                variant="standard"
                validate={required()}
              />
              <MediaSequenceInput />
              <AddImageInput/>
              <AddVideoInput/>
            </UrlCacheProvider>
          </LanguageLookupProvider>
        </MediaLookupProvider>
      </SimpleForm>
    </Edit>
  )
}

