import React from 'react';
import _ from 'lodash';
import { Typography} from '@mui/material';

import { useRecordContext } from 'ra-core';
import { useDetailPanelStyles } from '../../theme';
import { PublicFieldProps } from 'react-admin';

export const DetailsDateField = (props:PublicFieldProps) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  const toLocalDateString = (value: Date | string) => {
    const date = value instanceof Date ? value : new Date(value);
    return date.toLocaleString()
  }

  return (
    <>
      <Typography
        className={styles.classes.label}
        variant="caption"
      >
        {props.label}
      </Typography>
      <Typography
        className={styles.classes.field}
        variant="body2"
      >
        { record ? toLocalDateString(_.get(record, props.source!)) : ''}
      </Typography>
    </>
  )
}

