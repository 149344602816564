import React from 'react'

import {Button, ButtonProps} from 'react-admin'
import { useNavigate} from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

import { useHeuristicReturnPath } from './useHeuristicReturnPath';

export type HeuristicReturnButtonProps =
  {
    origins?:Array<string>
    defaultPath?:string
  } &
  Omit<ButtonProps, 'startIcon' | 'href' | 'onClick'> 

export const HeuristicReturnButton = (props:HeuristicReturnButtonProps) => {

  const {
    origins,
    defaultPath,
    style,
    color,
    variant,
    size,
    label
  } = props;
  const returnPath = useHeuristicReturnPath(origins??[],defaultPath)
  const navigate = useNavigate();

  const navigateReturn = () => {
    if (returnPath) navigate(returnPath);
    else navigate(-1);
  }

  return (
    <Button
      style={style ?? { marginBottom: '1em' }}
      color={color ?? 'primary'}
      variant={variant ?? 'text'}
      size={size ?? 'small'}
      label={label ?? 'return'}
      onClick={navigateReturn}
    >
      <KeyboardBackspaceIcon />
    </Button>
  );
}
