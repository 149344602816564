export type GroupName = 'Admin' | 'Team' | 'Customer';

export interface ApiGroupType {
  groupName: GroupName,
  description?: string,
  precedence: number,
  createdAt: Date,
  updatedAt: Date,
}

export type Group = ApiGroupType & {id: string};

export const toGroup = (group: ApiGroupType): Group => {
  const {groupName} = group;
  return {
    id: groupName,
    ...group
  };
}

export const GroupNames: {
  Admin: GroupName, Team: GroupName, Customer: GroupName
} = {
  Admin: 'Admin',
  Team: 'Team',
  Customer: 'Customer'
}
