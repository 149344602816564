import React, {useRef} from "react";

import {
  Edit,
  EditProps,
  SimpleForm,
  ImageField,
  ImageInput,
  useRecordContext,
  RaRecord
} from 'react-admin';

import {
  SaveCancelToolbar,
  LanguageInputField,
  ImagePlaceholder
} from "../../components";

import {PutResult} from '@aws-amplify/storage';
import {useNavigate} from 'react-router-dom';
import { cancelFileUploads } from "../../services";
import { maxImageDimensions, maxFileSize } from "../../utils";
import {MaxImageSize, MaxImageDimensions, transformImageRecord } from "./imageFileUtils";
import { ImageFileDetails } from "./imagefileDetails";
import { ImageFile } from "../../models";

const MediaFilesTitle = () => {
  const record = useRecordContext<ImageFile>()
  return <span>Image - { record ? record.imageFileName : '' }</span>
}

export const ImageFileEdit = (props:EditProps) => {


  const uploadList = useRef<Promise<PutResult>[]>([]);
  const navigate = useNavigate();

  const transform = (record: RaRecord): RaRecord => transformImageRecord(record as ImageFile, uploadList);
  const onCancel = () => {
    cancelFileUploads(uploadList);
    navigate(-1);
  }

  return (

    <Edit
      title={<MediaFilesTitle />}
      {...props}
      transform={transform}
      actions={false}
      mutationMode='pessimistic'
      aside={<ImageFileDetails/>}
    >

      <SimpleForm
        toolbar={<SaveCancelToolbar onCancel={onCancel}/>}
      >
        <LanguageInputField source='languages'/>
        <ImageInput
          label='Upload new image'
          source='_imageStorageKey'
          accept='image/png,image/jpeg'
          validate={[
            maxFileSize(MaxImageSize),
            maxImageDimensions(MaxImageDimensions)
          ]}
          placeholder={
            <ImagePlaceholder
              fileTypes={['*.jpg','*.png']}
              maxFileSize={MaxImageSize}
              maxDimensions={MaxImageDimensions}
            />}
        >
          <ImageField source='_imageStorageKey' />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )

};
