
import {
  DetailsPanel,
  DetailsDateField,
  DetailsTextField,
} from '../../components'


export const PlaylistDetails = () => {
  return (
    <DetailsPanel size='small'>
      <DetailsTextField label="Name" source="name"/>
      <DetailsDateField label="Created at" source="createdAt"/>
      <DetailsDateField label="Updated at" source="updatedAt"/>
    </DetailsPanel>
  )
}
