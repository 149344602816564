import {
    Menu,
    MenuProps,
    MenuItemLink,
    useLogout,
    usePermissions,
    Logout
} from 'react-admin'

import { MenuItem, ListItemIcon, Typography, Divider } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import ImageIcon from '@mui/icons-material/Image';
import TheatersIcon from '@mui/icons-material/Theaters';
import DescriptionIcon from '@mui/icons-material/Description';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import {useHasRole} from '../../components';
import { GroupNames } from '../../services';

export const MenuDrawer = (props: MenuProps) => {

  const isAdmin = useHasRole([GroupNames.Admin]);
  const isAdminOrTeam = useHasRole([GroupNames.Admin, GroupNames.Team]);

  return (
    <Menu {...props}>

      {
        isAdmin &&
        <>
          <MenuItemLink
            to='/ContentPage'
            primaryText="Pages"
            leftIcon={<ChromeReaderModeIcon />}
            sx={{borderRadius: '5px'}}
          />

          <MenuItemLink
            to='/Playlist'
            primaryText="Playlists"
            leftIcon={<SlideshowIcon/>}
            sx={{borderRadius:'5px'}}
          />

          <Divider sx={{marginX: '5px'}}/>

          <MenuItemLink
            to='/DocumentFile'
            primaryText="Documents"
            leftIcon={<DescriptionIcon/>}
            sx={{borderRadius:'5px'}}
          />

          <MenuItemLink
            to='/VideoFile'
            primaryText="Videos"
            leftIcon={<TheatersIcon/>}
            sx={{borderRadius:'5px'}}
          />

          <MenuItemLink
            to='/ImageFile'
            primaryText="Images"
            leftIcon={<ImageIcon/>}
            sx={{borderRadius:'5px'}}
          />

          <Divider sx={{marginX: '5px'}}/>

          <MenuItemLink
            to='/TranslationKey'
            primaryText="Translations"
            leftIcon={<TranslateIcon/>}
            sx={{borderRadius:'5px'}}
          />

          <MenuItemLink
            to='/Language'
            primaryText="Languages"
            leftIcon={<LanguageIcon/>}
            sx={{borderRadius:'5px'}}
          />

          <Divider sx={{marginX: '5px'}}/>

        </>
      }
      {isAdminOrTeam &&
        <>
          <MenuItemLink
            to='/users'
            primaryText="User"
            leftIcon={<PersonIcon/>}
            sx={{borderRadius:'5px'}}
          />

          <Divider sx={{marginX: '5px'}}/>
        </>
      }
      <Logout/>
    </Menu>
  )
}
