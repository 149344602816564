import React from "react";

import {
  Button,
  SaveButton,
  Toolbar,
} from 'react-admin';

import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';

interface SaveCancelToolbarProps{
  disabled?: boolean,
  onCancel?:()=>void
}

export const SaveCancelToolbar = (props:SaveCancelToolbarProps) => {
  const navigate = useNavigate();
  const {onCancel, disabled} = props;

  const onCancelClicked = () => {
    if (onCancel) onCancel();
    else navigate(-1);
  }

  return (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      <Button
        label='Cancel'
        onClick={onCancelClicked}
      >
        <CloseIcon/>
      </Button>
      {!disabled && <SaveButton/>}
    </Toolbar>
  )
}

