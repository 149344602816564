import React from "react";
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  TextInput,
  TopToolbar,
  CreateButton,
} from "react-admin";

import {TranslationKeyLocalisationsField} from './keyLocalisationsField'

const TranslationKeyFilters = [
  <TextInput
    label="Search keys"
    source="key.contains"
    alwaysOn
    resettable
    key='searchFilter'
    parse={(v) => {
        v = v.toUpperCase();
        v = v.replace(" ", "_");
        return v;
      }
    }
  />
]

export const TranslationKeyList = (props: ListProps) => {

  return (
    <List
      {...props}
      exporter={false}
      actions={<TranslationKeyActions/>}
      filters={TranslationKeyFilters}
      sort={{ field: 'key', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField sx={{wordBreak:'break-all'}} source="key" label="Translation key" />
        <TranslationKeyLocalisationsField label='Localisations'/>
      </Datagrid>
    </List >
  )
};

const TranslationKeyActions= () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='add translation key'/>
    </TopToolbar>
  );
}

