
import React, { useState } from "react";

import {
  required,
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  useRedirect,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { useDataProvider } from "ra-core";
import { SaveCancelToolbar } from "../../components";
import { FormError } from "../../utils";
import { Translation } from "../../models";

export const TranslationCreate = (props:CreateProps) => {
  const dataProvider = useDataProvider();

  const redirect = useRedirect();
  const onSuccess = (data:Translation) => redirect('show', '/TranslationKey',data.translationKeyID);

  const validateForm = async (data:Partial<Translation>):Promise<FormError<Translation>> => {
    const errors:FormError<Translation> ={};

    if (data.languageID === undefined || data.languageID === '') {
      errors.languageID = 'language required';
    }
    else
    {
      const translations = await dataProvider.getList(
        'Translation',
        {
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'translationKeyID', order: 'DESC' },
          filter: {
            translationKeyID: { eq: data.translationKeyID },
            languageID: { eq: data.languageID }
          }
        }
      );

      if (translations.total! > 0) {
        errors.languageID = 'translation already exists';
      }
    }

    if (data.value === null || data.value === undefined || data.value === '') {
      errors.value = 'translation required';
    }

    return errors
  };

  return (
    <Create
      {...props}
      mutationOptions={{ onSuccess }}
      title='Add Translation'
    >
      <SimpleForm validate={validateForm} toolbar={<SaveCancelToolbar />}>
        <ReferenceInput
          source="translationKeyID"
          reference="TranslationKey"
        >
          <SelectInput
            label="Translation key"
            variant='standard'
            optionText="key"
            disabled={true}
          />
        </ReferenceInput>

        <ReferenceInput
          source="languageID"
          reference="Language"
          validate={required()}
        >
          <SelectInput
            label="Language"
            variant='standard'
            optionText="displayName"
          />
        </ReferenceInput>

        <TextInput
          fullWidth
          source="value"
          multiline={true}
          label="Translation"
          variant="standard"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
}

