
import React from "react";

import {
  List,
  ListProps,
  Datagrid,
  CreateButton,
  TopToolbar,
  TextInput,
  DateField,
  BooleanField,
  TextField,
  BulkDeleteButton,
  useRecordContext,
  ImageFieldProps,
} from 'react-admin';

import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {Box} from '@mui/material';

import { LanguageField, usePresignedUrl} from "../../components";
import { ImageFile } from "../../models";

const ImageFileFilters = [
  <TextInput
    label="Search file names"
    source="imageFileName.contains"
    resettable
    alwaysOn
    key='searchFilter'
  />
];

export const ImageFileList = (props:ListProps) => {

  return (

    <List
      {...props}
      title='Images'
      exporter={false}
      actions={<ImageFileActions/>}
      filters={ImageFileFilters}
      bulkActionButtons={<BulkDeleteButton/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
      >
        <ImageThumbnail label='' sortable={false}/>
        <TextField label='File name' source='imageFileName'/>
        <BooleanField
          label='Restricted access'
          source='protected'
          TrueIcon={HttpsIcon}
          FalseIcon={LockOpenIcon}
        />
        <LanguageField sortable={false}/>
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List >
  )
};

const ImageThumbnail = (props:Omit<ImageFieldProps, 'record'|'source'>) => {
  const record = useRecordContext<ImageFile>();
  const [loading, url, error] = usePresignedUrl(record.imageStorageKey);
  return (
    loading || error ? null :
      <Box
        component='img'
        src={url}
        maxHeight='3rem'
        display='flex'
      />
  )
}

const ImageFileActions = () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='add image'/>
    </TopToolbar>
  );
}




