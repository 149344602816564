import React, { useState} from 'react';

import {
  AppBar,
  AppBarProps,
  UserMenu,
  UserMenuProps,
  Logout,
  useUserMenu,
  Toolbar,
  Button,
  useLogout
} from "react-admin";

import PersonOffIcon from '@mui/icons-material/PersonOff';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  DialogProps,
  Typography
} from '@mui/material';

import {Auth} from 'aws-amplify';

export const MenuBar = (props: AppBarProps) => {
    return <AppBar userMenu={<ProfileMenu/>} {...props} />;
};

interface DialogControl {
  open: ()=>void,
  close: ()=>void
}

const ProfileMenu = (props: UserMenuProps) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const dialogControl = {
      open: () => setDialogOpen(true),
      close: () => setDialogOpen(false)
    };

    return (
      <>
        <UserMenu {...props}>
          <DeleteAccountButton dialog={dialogControl}/>
          <Logout/>
        </UserMenu>
        <DeleteAccountDialog open={dialogOpen} dialog={dialogControl}/>
      </>
    )
}


const DeleteAccountButton = React.forwardRef((props: MenuItemProps & {dialog: DialogControl} , ref: any) => {

  const {dialog, ...menuItemProps} = props;

  const userMenu = useUserMenu();

  const openDialog = () => {
    dialog.open();
    userMenu.onClose();
  }

  return (
    <MenuItem
      {...menuItemProps}
      ref={ref}
      onClick={openDialog}
    >
        <ListItemIcon>
          <PersonOffIcon/>
        </ListItemIcon>
        <ListItemText primary='Delete Account'/>
    </MenuItem>
  )
});

const DeleteAccountDialog = (props:DialogProps & {dialog: DialogControl}) => {

  const {dialog, ...dialogProps} = props;
  const logout = useLogout();

  const deleteAccount = async () => {
    await Auth.deleteUser();
    logout();
  }

  return (
    <Dialog
      onClose={dialog.close}
      {...dialogProps}
    >
      <Box minWidth='350px'>
        <DialogTitle>Delete Account</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography color='error'>
             <span>Your account will be deleted permanently!</span><br/>
          </Typography>
          <Box height='1rem'/>
          <Typography>
            <span>You will no longer be able to sign in to</span><br />
            <span>this control panel or the show room.</span><br/>
          </Typography>
        </DialogContent>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Button
            label="cancel"
            size="medium"
            onClick={dialog.close}
          >
            <CloseIcon />
          </Button>
          <Button
            label="delete"
            size="medium"
            onClick={deleteAccount}
          >
            <DeleteForeverIcon />
          </Button>
        </Toolbar>
      </Box>
    </Dialog>
  )
}
