import React ,{useEffect, useState}  from "react";

import {
  PublicFieldProps,
  useRecordContext,
  useGetList,
  Identifier,
  SingleFieldList,
  ChipField,
  ListContextProvider
} from "react-admin";
import { DatastorePagination } from "../../services";

import {Language} from "../../models";

const useKeyLocalisationLookup = (keyId:Identifier):Array<Language> =>{

  const { data:languages} = useGetList('Language',{pagination:DatastorePagination.All});
  const {data: translations} = useGetList(
    'Translation',
    {
      pagination: DatastorePagination.All,
      filter: {translationKeyID: {eq: keyId}}
    });

  const [languageList, setLanguageList] = useState<Array<Language>>([]);

  useEffect(() => {
    if(languages && translations){
      const languageMap = new Map<string,Language>(languages.map(l => [l.id, l]));
      const translationLanguages = translations
          .map(t => languageMap.get(t.languageID))
          .sort((a,b) => (a?.displayName ?? '') > (b?.displayName ?? '') ? 1 : -1)
          .filter(l => l) as Array<Language>;
      setLanguageList(translationLanguages);
    }
  },[languages,translations])

  return languageList;
}

export const TranslationKeyLocalisationsField= (props: PublicFieldProps) => {
  const record = useRecordContext(props);
  const languageList = useKeyLocalisationLookup(record.id);
  return (
    <ListContextProvider value={{data: languageList}}>
      <SingleFieldList linkType={false}>
        <ChipField source='displayName' variant='outlined'/>
      </SingleFieldList>
    </ListContextProvider>
  )
}
