
import React, {useState} from 'react';
import {
  createFilterOptions,
  Autocomplete,
  TextField,
  Stack,
  Button
} from '@mui/material';

import {
  useGetList,
  Labeled,
  useInput
} from 'react-admin';

import {DocumentFile} from '../../models';
import AddIcon from '@mui/icons-material/Add';
import { DatastorePagination } from '../../services';
import {useWatch} from 'react-hook-form';

export const AddDocumentInput = () => {
  const documentListInput = useInput({source: 'documentFiles'});
  const documentFileIds = useWatch({name: 'documentFiles', defaultValue: []});

  const [selectedDocument, setSelectedDocument] = useState<DocumentFile|null>(null);
  const {data, isLoading}= useGetList<DocumentFile>('DocumentFile', {pagination:DatastorePagination.All});

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options:Array<DocumentFile>, state:any):Array<DocumentFile> => {
    return (defaultFilterOptions(options, state) as Array<DocumentFile>)
     .filter(img => !(documentListInput.field.value ?? []).includes(img.id))
     .slice(0, 100) as Array<DocumentFile>;
  }

  const addDocument = () => {
    if (selectedDocument) {
      const {id} = selectedDocument;
      const updatedSequence = documentFileIds.concat([id]);
      documentListInput.field.onChange(updatedSequence);
      setSelectedDocument(null);
    }
  }

  return (
    <Labeled>
      <Stack direction='row' alignItems='flex-end' gap='10px'>
        <Autocomplete
          sx={{minWidth:'300px'}}
          options={data ?? []}
          filterOptions={filterOptions}
          loading={isLoading}
          getOptionLabel={(option) => option.title}
          onChange={(event, value, reason, details) => {
            if(value?.id !== selectedDocument?.id) setSelectedDocument(value)
          }}
          value={selectedDocument}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            );
          }}
          renderInput={(params) =>
            <TextField
              multiline
              placeholder='search document...'
              variant='standard'
              {...params}
              label="Select Document"
            />}
        />
        <Button
          size='medium'
          startIcon={<AddIcon />}
          onClick={addDocument}
        >Add Document</Button>
      </Stack>
    </Labeled>
  );
}

