import { makeStyles } from "./makeStyle";

export const usePlaylistStyles = makeStyles({ name: 'Playlist' })(
  (theme)=>({
  item: {
    borderColor: theme.palette!.divider,
    borderWidth: "thin",
  },
  itemBottom: {
    borderBottomStyle: "solid",
  },
  itemTop:{
    borderTopStyle: "solid",
  },
  itemContent:{
    flex: "auto",
    display: "flex",
    flexDirection: "row",
    background: "white",
    borderRadius: "4px",
    alignItems: "center"
  },
  dragIndicator:{
    color: "rgba(0, 0, 0, 0.54)"
  },
  image:{
    width:"100%",
    height:"100%",
    objectFit: "cover",
  },
  thumbnail:{
    height: "3em",
    width: "5em",
    display: "flex",
    padding: "12px 24px",
    position:"relative"
  },
  icon:{
    display: "flex",
    padding: "12px 24px",
    color:"#6e6e6e",
    fontSize: "3em",
  },
  filename: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  overlayIcon: {
    position: "absolute",
    left: "24px",
    color: "#fff8",
    zIndex: 2
  },
  //removeButton: {
    //color: theme.palette.error.main
  //},
  disabled:{
    filter: "opacity(40%)"
  }
}));

