

import {
  Button,
  required,
  ReferenceInput,
  SelectInput,
  Form,
  SaveButton,
  Toolbar,
  useNotify,
  usePermissions,
  useEditContext,
  EditBase,
} from 'react-admin';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Stack,
  Box
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { User } from "../../services";

import CloseIcon from '@mui/icons-material/Close';
import { GroupNames } from "../../services";


export const UserEditDialog = () =>{
  const navigate = useNavigate();
  const {isLoading,permissions} = usePermissions();
  const notify = useNotify();

  const closeDialog = () => {
    navigate(-1);
  }

  const onSuccess = (data:User) => {
    notify(`User ${data.email} updated.`, {type: 'success', undoable: false});
    navigate(-1);
  };

  return (
    <Dialog open onClose={closeDialog}>
      <Box minWidth='350px'>
        <EditBase
          resource='users'
          mutationMode='pessimistic'
          mutationOptions={{onSuccess}}
        >
          <Form >
            <DialogTitle>
              <DialogTitleText/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Stack gap='0px' spacing='0px'>
                <ReferenceInput label='User Type' source='group' reference='groups'>
                  <SelectInput
                    label='User Type'
                    optionText='groupName'
                    validate={[required()]}
                    variant='standard'
                    isLoading={isLoading}
                    disabled={!permissions || !permissions.includes(GroupNames.Admin) }
                  />
                </ReferenceInput>
              </Stack>
            </DialogContent>
            <Toolbar sx={{ justifyContent:'space-between'}}>
              <Button label='cancel' onClick={closeDialog} size='medium'>
                <CloseIcon />
              </Button>
              <SaveButton/>
            </Toolbar>
          </Form>
        </EditBase>
      </Box>
    </Dialog>
  )
}


const DialogTitleText= () => {

  const {record} = useEditContext();
  return (
    <>
      {`Edit ${record?.email ?? 'User'}`}
    </>
  )
}
