import {
  DetailsPanel,
  DetailsDateField,
  DetailsTextField,
  DetailsBooleanField,
  DetailsFileField
} from '../../components'

import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const DocumentFileDetails = () => {
  return (
    <DetailsPanel size="large">
      <DetailsTextField label="Title" source="title" />
      <DetailsFileField label="File" source='documentStorageKey' nameSource='documentFileName'/>
      <DetailsBooleanField
        label='Restricted acess'
        source='protected'
        TrueIcon={HttpsIcon}
        FalseIcon={LockOpenIcon}
      />
      <DetailsDateField label="Created at" source="createdAt" />
      <DetailsDateField label="Updated at" source="updatedAt" />
    </DetailsPanel>
  )
}
