import * as uuid from "uuid";

export const attachStorageId = (filename:string, id?:string):string => {
  const [name, extension] = splitFileExtension(filename);
  if (!id || !uuid.validate(id)) id = uuid.v4();
  return `${name}_${id}.${extension}`;
};

export const splitStorageId = (filename:string):[string|undefined,string]=> {
  const [name, extension] = splitFileExtension(filename);

  const uuidRx = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}');
  if (filename.match(uuidRx)){
    const tokens = name.split("_");
    const [id] = tokens.slice(-1);
    const fullname = [tokens.slice(0, -1).join("_"), extension].join(".");

    if (uuid.validate(id)) return [id, fullname];
    else return [undefined, fullname];
  } else {
    return [undefined, filename];
  }
};

export const splitFileExtension = (filename:string):[string,string] => {
  const tokens = filename.split(".");
  const [extension] = tokens.slice(-1);
  const name = tokens.slice(0, -1).join(".");
  return [name, extension];
};


