
import React, {
  createContext,
  useContext,
  ProviderProps
} from 'react';



interface CacheItem {
  url: string
  updatedAt:number
}

export class UrlCache {

  cache: Map<string, CacheItem>

  constructor() {
    this.cache = new Map<string, CacheItem>();
  }

  has(key: string, since?:number): boolean {
    const item = this.cache.get(key);
    if (item) {
      if (since) {
        return item.updatedAt > since;
      }
      return true
    }
    else return false;
  }

  get(key: string, since?:number): string | undefined {
    if(this.has(key, since)){
      return this.cache.get(key)!.url;
    }
    return undefined;
  }

  set(key: string, url: string) {
    this.cache.set(key, {
      url: url,
      updatedAt: Date.now()
    });
  }
}

export const UrlCacheContext =
  createContext<UrlCache>(new UrlCache());

export const UrlCacheProvider =
  (props: Omit<ProviderProps<UrlCache>,'value'>) =>
{
  const value = new UrlCache();
  return <UrlCacheContext.Provider value={value} {...props}> {props.children} </UrlCacheContext.Provider>
}

export const useUrlCacheContext = () => useContext(UrlCacheContext);
