
import React, {useState, useEffect} from 'react';

import {
  createFilterOptions,
  Autocomplete,
  TextField,
} from '@mui/material';

import {
  useGetList,
  Labeled,
  useInput,
  InputProps
} from 'react-admin';

import {Playlist} from '../../models';
import { DatastorePagination } from '../../services';

export const PlaylistReferenceInput = (props: {label: string}) => {

  const {label} = props;
  const playlistInput = useInput({source:'playlists'} as InputProps<Array<string>>)
  const {data, isLoading} = useGetList<Playlist>('Playlist', {pagination:DatastorePagination.All});

  const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist| null>(null);

  useEffect(() => {
    if(isLoading)return;
    if (playlistInput.field.value === undefined) playlistInput.field.onChange([]);
    else if (playlistInput.field.value.length > 0) {
      const playlistId = playlistInput.field.value[0];
      const playlists = data!.filter(pl => pl.id === playlistId);
      setSelectedPlaylist(playlists.length > 0 ? playlists[0] : null);
    }
    else {
      setSelectedPlaylist(null);
    }
  }, [playlistInput, isLoading])

  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options:Array<Playlist>, state:any):Array<Playlist> => {
    return (defaultFilterOptions(options, state) as Array<Playlist>)
     .slice(0, 100) as Array<Playlist>;
  }

  const updateRecord = (playlist: Playlist| null) => {
    playlistInput.field.onChange( playlist ? [playlist.id] : []);
  }

  const isOptionEqualToValue = (option:Playlist, value:Playlist):boolean =>{
    if (!option || !value) return false;
    return option.id === value.id;
  }

  return (
    <Labeled>
        <Autocomplete
          sx={{minWidth:'300px'}}
          value={selectedPlaylist}
          options={data ?? []}
          filterOptions={filterOptions}
          isOptionEqualToValue={isOptionEqualToValue}
          loading={isLoading}
          getOptionLabel={(option) => option?.name ?? ''}
          onChange={(event,value,reason,details) => {updateRecord(value)}}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          renderInput={(params) =>
            <TextField
              multiline
              placeholder='search playlist...'
              variant='standard'
              {...params}
              label={label}
            />}
        />
    </Labeled>
  );
}

