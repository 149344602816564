import { useState } from 'react';
import { Controller} from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import { FormGroup, FormControlLabel, FormHelperText, Link, Checkbox, Typography, Box  } from '@mui/material';

export interface TermsAndConditionFrom {acceptedTerms: boolean}

export const TermsAndConditionsCheckbox = (props:{formController: UseFormReturn<TermsAndConditionFrom & any, any>}) => {
  const {control} = props.formController;

  return (
    <Controller
      name="acceptedTerms"
      rules={{
        required: 'You must accept the terms and conditions.',
      }}
      control={control}
      defaultValue={false}
      render={({field, fieldState}) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox {...field}/>
            }
            label={
              <Box display='flex' flexDirection='row'>
                <Typography component='pre'>Accept </Typography>
                <Link target='_blank' href='/#/legal'> Terms & Conditions</Link>
                <Typography component='pre'> *</Typography>
              </Box>
            } />
          {fieldState.error && <FormHelperText sx={{color: 'error.main'}}>{fieldState.error?.message}</FormHelperText>}
        </FormGroup>
      )}
    />
  );
}
