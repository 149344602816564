import React, {useRef} from 'react';

import {
  required,
  Create,
  CreateProps,
  SimpleForm,
  ImageField,
  ImageInput,
  BooleanInput,
  RaRecord
} from 'react-admin';

import {useNavigate} from 'react-router-dom'; 
import { cancelFileUploads } from '../../services';
import { PutResult } from '@aws-amplify/storage';
import { maxImageDimensions, maxFileSize } from "../../utils";
import {MaxImageSize, MaxImageDimensions, transformImageRecord } from "./imageFileUtils";
import { ImageFile } from '../../models';

import {
  SaveCancelToolbar,
  LanguageInputField,
  ImagePlaceholder
} from "../../components";

export const ImageFileCreate = (props: CreateProps) => {

  const uploadList = useRef<Promise<PutResult>[]>([]);
  const navigate = useNavigate();

  const transform = (record: RaRecord): RaRecord => transformImageRecord(record as ImageFile, uploadList);
  const onCancel = () => {
    cancelFileUploads(uploadList);
    navigate(-1);
  }

  return (
    <Create
      {...props}
      title='Add Image'
      transform={transform}
      redirect='list'
    >
      <SimpleForm
        redirect="list"
        toolbar={<SaveCancelToolbar onCancel={onCancel}/>}
      >
        <LanguageInputField source='languages'/>
        <BooleanInput source='protected' label='authenticated users only' />
        <ImageInput
          label='Upload image'
          source='_imageStorageKey'
          accept='image/png,image/jpeg'
          validate={[
            required(),
            maxFileSize(MaxImageSize),
            maxImageDimensions(MaxImageDimensions)
          ]}
          placeholder={
            <ImagePlaceholder
              fileTypes={['*.jpg','*.png']}
              maxFileSize={MaxImageSize}
              maxDimensions={MaxImageDimensions}
            />}
          options={{
            maxSize: MaxImageSize,
            maxFiles: 1,
          }}
        >
          <ImageField source='_imageStorageKey' />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
}
