import React, {useRef, useEffect} from "react";
import { PutResult } from '@aws-amplify/storage';
import {
  required,
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  BooleanInput,
  RaRecord
} from 'react-admin';

import { useNavigate } from 'react-router-dom';
import { cancelFileUploads } from '../../services';
import { maxFileSize } from "../../utils";
import { MaxDocumentSize, transformDocumentRecord } from "./documentFileUtils";
import { DocumentFile } from "../../models";

import {
  SaveCancelToolbar,
  LanguageInputField,
  DocumentPlaceholder
} from "../../components";

export const DocumentFileCreate = (props: CreateProps) => {

  const uploadList = useRef<Promise<PutResult>[]>([]);
  const navigate = useNavigate();

  const transform = (record: RaRecord): RaRecord => transformDocumentRecord(record as DocumentFile, uploadList);
  const onCancel = () => {
    cancelFileUploads(uploadList);
    navigate(-1);
  }

  return (
    <Create
      title='Add Document'
      {...props}
      transform={transform}
      redirect='list'
    >
      <SimpleForm
        redirect="list"
        toolbar={<SaveCancelToolbar onCancel={onCancel} />}
      >
        <LanguageInputField source='languages'/>
        <BooleanInput source='protected' label='authenticated users only' />
        <TextInput source='title' variant='standard' validate={required()}/>
        <FileInput
          label='Upload document'
          source='_documentStorageKey'
          accept='application/pdf'
          validate={[
            required(),
            maxFileSize(MaxDocumentSize),
          ]}
          placeholder={
            <DocumentPlaceholder
              fileTypes={['*.pdf']}
              maxFileSize={MaxDocumentSize}
            />
          }
        >
          <FileField
            source="_documentStorageKey"
            title="documentFileName"
            src="_documentStorageKey"
            target="_blank"
          />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

