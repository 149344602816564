
import React, { useState } from 'react';
import { Controller} from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Validate } from '../../utils';

import {
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';

export interface ConfrimedPasswordForm {password: string, confirmPassword: string}

export const ConfirmedPasswordInput = (props:{formController: UseFormReturn<ConfrimedPasswordForm & any,any>}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => { setShowPassword(!showPassword); };

  const {control, getValues, watch, trigger, formState:{touchedFields}} = props.formController;
  // Forces validation of the confirmPassword field if it 
  // has been changed and the password field changes
  React.useEffect(() => {
    const {unsubscribe} = watch((value, {name, type}) => {
      if (
        name === 'password' && 
        type === 'change' &&
        touchedFields.confirmPassword
      )
      {
        trigger('confirmPassword');
      } 
    });
    return () => unsubscribe();
  }, [watch, touchedFields, trigger]);

  return (
    <>
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{
          required: 'Password is required.',
          validate: () => {
            return Validate.password(getValues('password'))
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              label="new password"
              variant="standard"
              required={true}
              error={fieldState.error !== undefined}
              helperText={fieldState.error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...field}
            />
          )
        }}
      />

      <Controller
        name="confirmPassword"
        control={control}
        defaultValue=""
        rules={{
          required: 'Passwords must match.',
          validate: (value: string) => {
            if (value === getValues('password')) return true
            return 'Passwords must match.'
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              type={showPassword ? 'text' : 'password'}
              autoComplete="off"
              label="confirm new password"
              variant="standard"
              required={true}
              error={fieldState.error !== undefined}
              helperText={fieldState.error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...field}
            />
          )
        }}
      />
    </>
  )
}

