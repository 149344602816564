import { Main, LegalPage } from '../../views';
import { HashRouter, Route, Routes } from 'react-router-dom';

import awsExports from "../../aws-exports";
import { Amplify, AuthModeStrategyType } from 'aws-amplify';


enum LOG_TYPE {
    DEBUG = "DEBUG",
    ERROR = "ERROR",
    INFO = "INFO",
    WARN = "WARN",
    VERBOSE = "VERBOSE"
}

Amplify.configure({
  ...awsExports,
  Api: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
});

if (process.env.NODE_ENV === 'development')
  Amplify.Logger.LOG_LEVEL = LOG_TYPE.DEBUG;

export const Root = () => {
  return(
    <HashRouter>
      <Routes>
        <Route path="/legal" element={ <LegalPage/> }/>
        <Route path="/*" element={ <Main/> }/>
      </Routes>
    </HashRouter>
  )
}

