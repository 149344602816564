
import React from "react";

import {
  List,
  ListProps,
  Datagrid,
  CreateButton,
  TopToolbar,
  TextInput,
  DateField,
  BooleanField,
  TextField,
  BulkDeleteButton,
  useRecordContext,
  ImageFieldProps,
} from 'react-admin';

import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {Box} from '@mui/material';

import { LanguageField, usePresignedUrl} from "../../components";
import { VideoFile } from "../../models";


const VideoFileFilters = [
  <TextInput
    label="Search file name"
    source="videoFileName.contains"
    alwaysOn
    resettable
    key='searchFilter'
  />
];

export const VideoFileList = (props:ListProps) => {

  return (
    <List
      {...props}
      title='Videos'
      exporter={false}
      filters={VideoFileFilters}
      actions={<VideoFileActions/>}
      bulkActionButtons={<BulkDeleteButton/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        rowClick="edit"
      >
        <VideoThumbnail label='' sortable={false}/>
        <TextField label='File name' source='videoFileName'/>
        <BooleanField
          label='Restricted access'
          source='protected'
          TrueIcon={HttpsIcon}
          FalseIcon={LockOpenIcon}
        />
        <LanguageField sortable={false}/>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List >
  )
};

const VideoThumbnail = (props:Omit<ImageFieldProps, 'record'|'source'>) => {
  const record = useRecordContext<VideoFile>();
  const [loading, url, error] = usePresignedUrl(record.posterStorageKey);
  return (
    loading || error ? null :
      <Box
        component='img'
        src={url}
        maxHeight='3rem'
        display='flex'
      />
  )
}

const VideoFileActions = () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='add video'/>
    </TopToolbar>
  );
}
