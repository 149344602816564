
import { TextField, } from '@mui/material';
import {Controller} from 'react-hook-form';
import _ from 'lodash';

import { UseFormReturn } from 'react-hook-form/dist/types';

export interface ConfirmationCodeForm {password: string}

export const ConfirmationCodeInput = (props:{formController: UseFormReturn<ConfirmationCodeForm & any,any>}) => {
  const {control} = props.formController;

  return (
    <Controller
      name="code"
      control={control}
      defaultValue=""
      rules={{
        required: 'Code is required.',
      }}
      render={({ field, fieldState }) => {
        return (
          <TextField
            autoComplete="one-time-code"
            label="confirmation code"
            variant="standard"
            required={true}
            error={fieldState.error !== undefined}
            helperText={fieldState.error?.message}
            {...field}
          />
        )
      }}
    />
  )
}
