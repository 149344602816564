import React from 'react';

import {Box, Typography} from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { ImageDimensions } from '../../utils';
import { formatFloat } from '../../utils';

import {useMediaDropFieldStyle} from '../../theme';

export const ImagePlaceholder = (props:{fileTypes:string[],maxFileSize:number, maxDimensions:ImageDimensions}) => {
  const styles = useMediaDropFieldStyle()
  const {fileTypes, maxFileSize, maxDimensions} = props;

  const formatConstraints = () =>{
    const acceptedTypes = fileTypes.join(', ');
    const acceptedSize = `${formatFloat((maxFileSize / 1e6),2)}Mb`
    const acceptedDimensions = `${maxDimensions.width}x${maxDimensions.height}px` 
    return (`(${acceptedTypes}, max.: ${acceptedSize}, ${acceptedDimensions})`);
  }
  return (
    <Box className={styles.classes.Placeholder}>
      <div className={styles.classes.Icon}>
        <InsertPhotoIcon fontSize="inherit" />
      </div>
      <Typography className={styles.classes.Text} variant="body2">
        Drop an image or click and select it.
      </Typography>
      <Typography className={styles.classes.Text} variant='caption'>
        {formatConstraints()}
      </Typography>
    </Box>
  )
}
