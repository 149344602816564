import React, {useState, useEffect } from 'react';
import { createFilterOptions, Autocomplete, TextField, Stack, Button } from '@mui/material';

import { useGetList, Labeled, useInput } from 'react-admin';
import {useWatch} from 'react-hook-form';
import { DatastorePagination } from '../../services';
import { VideoFile} from '../../models';
import AddIcon from '@mui/icons-material/Add';

export const AddVideoInput= () => {

  const sequenceInput = useInput({source: 'sequence'});
  const sequenceItemIds = useWatch({name: 'sequence', defaultValue:[]});

  const videoListInput = useInput({source: 'videoFiles'});
  const videoListItemIds = useWatch({name: 'videoFiles', defaultValue:[]});

  const [selectedVideo, setSelectedVideo] = useState<VideoFile|null>(null);
  const {data:videoFiles, isLoading}= useGetList<VideoFile>('VideoFile', {pagination:DatastorePagination.All});

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options:Array<VideoFile>, state:any):Array<VideoFile> => {
    return (defaultFilterOptions(options, state) as Array<VideoFile>)
     .filter(vdo => !(sequenceInput.field.value ?? []).includes(vdo.id))
     .slice(0, 100) as Array<VideoFile>;
  }

  const addVideo = () => {
    if (selectedVideo) {
      const {id} = selectedVideo;

      const updatedSequence = sequenceItemIds.concat([id]);
      sequenceInput.field.onChange(updatedSequence);

      const updatedVideoList = videoListItemIds.concat([id]);
      videoListInput.field.onChange(updatedVideoList);

      setSelectedVideo(null);
    }
  }

  return (
    <Labeled>
      <Stack direction='row' alignItems='flex-end' gap='10px'>
        <Autocomplete
          sx={{minWidth:'300px'}}
          options={videoFiles ?? []}
          filterOptions={filterOptions}
          loading={isLoading}
          getOptionLabel={(option) => option.videoFileName}
          onChange={(event,value,reason,details) => {setSelectedVideo(value)}}
          value={selectedVideo}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.videoFileName}
              </li>
            );
          }}
          renderInput={(params) =>
            <TextField
              multiline
              placeholder='search video...'
              variant='standard'
              {...params}
              label="Select Video"
            />}
        />
        <Button
          size='medium'
          startIcon={<AddIcon />}
          onClick={addVideo}
        >Add Video</Button>
      </Stack>
    </Labeled>
  );
}
