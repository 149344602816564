
import React, {useRef, useEffect} from "react";
import { PutResult } from '@aws-amplify/storage'

import {
  required,
  Create,
  CreateProps,
  SimpleForm,
  ImageField,
  ImageInput,
  FileInput,
  FileField,
  BooleanInput,
  RaRecord,
} from 'react-admin';

import {useNavigate} from 'react-router-dom';

import { transformVideoRecord, MaxVideoSize, MaxPosterSize, MaxPosterDimensions } from "./videoFileUtils";
import { maxImageDimensions, maxFileSize } from "../../utils";
import { VideoFile } from "../../models";
import { cancelFileUploads } from "../../services";

import {
  SaveCancelToolbar,
  LanguageInputField,
  ImagePlaceholder,
  VideoPlaceholder
} from "../../components";

export const VideoFileCreate = (props: CreateProps) => {

  const uploadList = useRef<Promise<PutResult>[]>([]);
  const navigate = useNavigate();

  const transform = (record: RaRecord): RaRecord => transformVideoRecord(record as VideoFile, uploadList);
  const onCancel = () => {
    cancelFileUploads(uploadList);
    navigate(-1);
  }

  return (
    <Create
      title='Add Video'
      {...props}
      transform={transform}
      redirect='list'
    >
      <SimpleForm
        redirect="list"
        toolbar={<SaveCancelToolbar onCancel={onCancel} />}
      >
        <LanguageInputField source='languages'/>
        <BooleanInput source='protected' label='authenticated users only' />
        <ImageInput
          label='Upload video poster'
          source='_posterStorageKey'
          accept='image/png,image/jpeg'
          validate={[
            required(),
            maxFileSize(MaxPosterSize),
            maxImageDimensions(MaxPosterDimensions)
          ]}
          placeholder={
            <ImagePlaceholder
              fileTypes={['*.jpg','*.png']}
              maxFileSize={MaxPosterSize}
              maxDimensions={MaxPosterDimensions}
            />}
        >
          <ImageField source='_posterStorageKey' />
        </ImageInput>
        <FileInput
          label="Upload video"
          source="_videoStorageKey"
          accept="video/mp4"
          validate={[
            required(),
            maxFileSize(MaxVideoSize),
          ]}
          placeholder={
            <VideoPlaceholder
              maxFileSize={MaxVideoSize}
              fileTypes={['*.mp4']}
            />
          }
        >
          <FileField
            source="_videoStorageKey"
            title="title"
            target="_blank"
          />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

