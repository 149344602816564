import React from 'react'

import {
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  ReferenceArrayFieldProps,
} from 'react-admin'


export type LanguageFieldProps = Omit<ReferenceArrayFieldProps, 'reference' | 'sort' | 'children'>

export const LanguageField = (props:LanguageFieldProps ) => {

  const {label, source} = props;
  return (

    <ReferenceArrayField
      label={label ?? 'Localisation'}
      reference="Language"
      source={source ?? 'languages'}
      sort={{
        field: 'displayName',
        order: 'ASC',
      }}
      {...props}
    >
      <SingleFieldList
        linkType={false}
        onClick={(e) => { e.stopPropagation() }}
      >
        <ChipField source="displayName" variant="outlined" />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

LanguageField.defaultProps = {
  addLabel: true,
  label: 'Localisation',
}


