import React, {useState} from 'react';
import {
  Tabs,
  Tab,
  Box,
  Stack,
  Divider,
  Button,
  Typography,
  TextField
} from '@mui/material';
import {TabPanel, TabContext} from '@mui/lab';
import { GetListParams, useDataProvider, GetOneParams} from 'react-admin';

import { API } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";


export const DebugPage = (props:any) => {
  const [tab, setTab] = useState('users');

  return (
    <Box
      sx={{
        zIndex: 100,
        flexGrow: 1,
        bgcolor: 'background.paper',
        flex:'1 1 auto',
        display: 'flex',
        height: 'fit-content',
      }}>
      <Tabs
        value={tab}
        orientation="vertical"
        onChange={(e, value) => setTab(value)}
        variant="scrollable"
        sx={{borderRight: 1, borderColor: 'divider'}}
      >
        <Tab label='users' value='users' />
        <Tab label='api' value='api' />
        <Tab label='auth' value='auth' />
      </Tabs>
      <TabContext value={tab}>
        <TabPanel sx={{width:'100%'}} value='users' tabIndex={0}>
          <UsersDebugPanel/>
        </TabPanel>
        <TabPanel sx={{width:'100%'}} value='api' tabIndex={1}>
          <ApiDebugPanel/>
        </TabPanel>
        <TabPanel sx={{width:'100%'}} value='auth' tabIndex={2}>
          <AuthDebugPanel/>
        </TabPanel>
      </TabContext>
    </Box>
  )
}


const UsersDebugPanel = () => {
  return (
    <Stack direction='column' gap={2} divider={<Divider/>} >
      <ListUsers/> 
      <GetUser/>
    </Stack >
  )
}

const AuthDebugPanel= () => {
  return (
    <Stack direction='column' gap={2} divider={<Divider/>} >
      <GetAccessToken/>
      <GetTempCredentials/>
    </Stack >
  )
}
const ApiDebugPanel= () => {
  return (
    < Stack direction='column' gap={2} divider={<Divider/>} >
      <ApiEnpoint/>
      < ApiGet />
    </Stack >
  )
}

const ListUsers = () => {
  const dataProvider = useDataProvider();
  const [result, setResult] = useState('');

  const fetchUsers = async () => {
    setResult('');
    const params: GetListParams = {
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter:{}
    }
    try {

    const users = await dataProvider.getList('users', params);
    setResult(JSON.stringify(users, null, 2));
    } catch (e) {
      setResult(JSON.stringify(e, null,2));
    }
  };

  return (
    <>
      <Button sx={{width:'200px'}} variant='outlined' onClick={fetchUsers}>Users GetList</Button>
      <Box display='flex'>
        <Typography>
          {result}
        </Typography>
      </Box>
    </>
  )
}

const GetUser= () => {
  const dataProvider = useDataProvider();
  const [result, setResult] = useState('');
  const [id, setId] = useState('');

  const fetchUser = async () => {
    setResult('');
    const params: GetOneParams= {
      id:id
    }
    try {

    const users = await dataProvider.getOne('users', params);
    setResult(JSON.stringify(users, null, 2));
    } catch (e) {
      setResult(JSON.stringify(e, null,2));
    }
  };

  return (
    <>
      <TextField sx={{width:'200px'}} label='User sub' value={id} onChange={(e) => setId(e.target.value)}/>
      <Button sx={{width:'200px'}} variant='outlined' onClick={fetchUser}>Users GetOne</Button>
      <Box display='flex'>
        <Typography>
          {result}
        </Typography>
      </Box>
    </>
  )
}

const ApiEnpoint = () => {
  const [result, setResult] = useState('');
  const [apiName, setApiName] = useState('');

  const getEndpoint = async () => {
    try {
      const endpoint = await API.endpoint(apiName);
      setResult(endpoint);
    } catch (e) {
      setResult(JSON.stringify(e));
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      <TextField sx={{width:'200px'}} label='Api Name' value={apiName} onChange={(e) => setApiName(e.target.value)}/>
      <Button sx={{width:'200px'}} variant='outlined' onClick={getEndpoint}>Get Endpoint</Button>
      <Box display='flex'>
        <Typography>
          {result}
        </Typography>
      </Box>
    </Stack>
  )
}

const ApiGet = () => {
  const [result, setResult] = useState('');
  const [apiName, setApiName] = useState('');
  const [path, setPath] = useState('');

  const apiGet = async () => {
    try {
      const init = {
        headers: {
          "Content-Type": "application/json",
          //Authorization: `${(await Auth.currentSession())
            //.getAccessToken()
            //.getJwtToken()}`,
        },
      };
      const response= await API.get(apiName,path, init)
      setResult(JSON.stringify(response));
    } catch (e) {
      setResult(JSON.stringify(e,null,2));
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      <TextField sx={{width:'200px'}} label='Api Name' value={apiName} onChange={(e) => setApiName(e.target.value)}/>
      <TextField sx={{width:'200px'}} label='Path' value={path} onChange={(e) => setPath(e.target.value)}/>
      <Button sx={{width:'200px'}} variant='outlined' onClick={apiGet}>Invoke Get</Button>
      <Box display='flex'>
        <Typography>
          {result}
        </Typography>
      </Box>
    </Stack>
  )
}
const GetAccessToken= () => {
  const [accessToken, setAccessToken] = useState<string|undefined>();
  const [identityToken, setIdentityToken] = useState<string|undefined>('');

  const getAccessToken = async () => {
      const accToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
      setAccessToken(accToken);
      setIdentityToken(idToken)
  }

  return (
    <Stack direction="column" spacing={2}>
      <Button
        sx={{ width: '200px' }}
        variant="outlined"
        onClick={getAccessToken}
      >
        Get Tokens
      </Button>
      {accessToken && (
        <>
          <Typography>Access Token</Typography>
          <Typography sx={{ maxWidth: '800px', wordWrap: 'break-word' }}>
            {accessToken}
          </Typography>
        </>
      )}
      {identityToken && (
        <>
          <Divider />
          <Typography>Identity Token</Typography>
          <Typography sx={{ maxWidth: '800px', wordWrap: 'break-word' }}>
            {identityToken}
          </Typography>
        </>
      )}
    </Stack>
  )
}

const GetTempCredentials = () => {
  const [credentials, setCredentials] = useState<undefined|any>();

  const getCredentials= async () => {
    const credentials = await Auth.Credentials.get();
    console.log(Auth.essentialCredentials(credentials));
    setCredentials(credentials)
  }

  return (
    <Stack direction="column" spacing={2}>
      <Button sx={{width:'200px'}} variant='outlined' onClick={getCredentials}>Get Credentials</Button>
      {credentials &&
        <>
          <Typography >Access Key Id</Typography>
          <Typography sx={{maxWidth: '800px', wordWrap: 'break-word'}}> {credentials.accessKeyId} </Typography>
          <Divider />
          <Typography >Secret Key</Typography>
          <Typography sx={{maxWidth: '800px', wordWrap: 'break-word'}}> {credentials.secretAccessKey} </Typography>
          <Divider />
          <Typography >Identity</Typography>
          <Typography sx={{maxWidth: '800px', wordWrap: 'break-word'}}> {credentials.identityId} </Typography>
          <Divider />
          <Typography >Expiration</Typography>
          <Typography sx={{maxWidth: '800px', wordWrap: 'break-word'}}> {(credentials.expiration as Date).toLocaleString()} </Typography>
        </>}
      </Stack>
  )
}
