import { combineDataProviders, DataProvider } from 'react-admin'

import DatastoreAdapter from './datastore/datastoreAdapter';
import { UsersDataProvider } from './userpool/usersDataProvider';
import { UserGroupsDataProvider } from './userpool/userGroupsDataProvider';

const datastoreAdapter = new DatastoreAdapter();
const usersDataProvider = new UsersDataProvider();
const userGroupsDataProvider = new UserGroupsDataProvider();

export const dataProvider = combineDataProviders((resource: string) => {
    switch (resource) {
        case 'users': { return usersDataProvider; }
        case 'groups': { return userGroupsDataProvider; }
        default : return datastoreAdapter as DataProvider;
    }
});

