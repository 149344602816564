import {
  DetailsPanel,
  DetailsDateField,
  DetailsTextField,
} from '../../components'

export const ContentPageDetails = () => {
  return (
    <DetailsPanel size='small'>
      <DetailsTextField label='Name' source='uniqueName' />
      <DetailsDateField label='Created at' source='createdAt' />
      <DetailsDateField label='Updated at' source='updatedAt' />
    </DetailsPanel>
  )
}
