import { useState } from 'react';
import { Controller} from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';

export interface PasswordForm {password: string}

export const PasswordInput = (props:{formController: UseFormReturn<PasswordForm & any,any>}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => { setShowPassword(!showPassword); };

  const {control} = props.formController;

  return (
    <Controller
      name="password"
      control={control}
      defaultValue=""
      rules={{ required: 'Password is required.' }}
      render={({ field, fieldState }) => {
        return (
          <TextField
            label="password"
            autoComplete="password"
            variant="standard"
            type={showPassword ? 'text' : 'password'}
            required={true}
            error={fieldState.error !== undefined}
            helperText={fieldState.error?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
          />
        )
      }}
    />
  )
}

