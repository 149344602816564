import { createMakeStyles } from "tss-react";
import { ReactAdminTheme } from "./reactAdminTheme";

const useTheme = () => {
  return ReactAdminTheme;
}

export const {
  makeStyles,
  useStyles //<- To use when you need css or cx but don't have custom classes 
} = createMakeStyles({ useTheme });
