
import React from 'react';
import _ from 'lodash';
import { Typography} from '@mui/material';

import { useRecordContext } from 'ra-core';
import { useDetailPanelStyles } from '../../theme';
import { BooleanFieldProps} from 'react-admin';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

export const DetailsBooleanField = (props:BooleanFieldProps) => {

  const {
    TrueIcon = DoneIcon,
    FalseIcon = ClearIcon
  } = props;

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  return (
    <>
      <Typography
        className={styles.classes.label}
        variant="caption"
      >
        {props.label}
      </Typography>
      <Typography
        className={styles.classes.field}
        variant="body2"
      >
        {record[props.source ?? ''] ? <TrueIcon/> : <FalseIcon/>}
      </Typography>
    </>
  )
}
