
import React, { memo} from "react";
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Datagrid,
  TextField,
  ReferenceManyField,
  SimpleShowLayout,
  Show,
  ShowProps,
  DeleteButton,
  DeleteButtonProps,
  DateField,
  useGetOne,
  useRecordContext,
  PublicFieldProps
} from 'react-admin';

import { TranslationKey, Translation } from "../../models";
import {HeuristicReturnButton} from "../../components";

const TranslationKeysTitle = () => {
  const record = useRecordContext<TranslationKey>();
  return <span>
    Translations - {record.key}
  </span>;
}

const CreateTranslationButton = () => {
  const record = useRecordContext<TranslationKey>()
  return (
    <Button
      component={Link}
      to={{
        pathname: '/Translation/create',
        state: {
          record: { translationKeyID: record.id }
        }
      }}
      size='small'
      color='primary'
      label='Add translation'
    >
      <AddIcon />
    </Button>
  );
}

const LanguageNameField = (props:PublicFieldProps) => {
  const record = useRecordContext<Translation>();
  const { data, isLoading, error } = useGetOne<Translation>('Language', {id: record.languageID});
  if (isLoading || error) return null;
  return (<TextField  record={data} source="displayName" />);
}

const DeleteTranslationButton = memo((props:DeleteButtonProps) => {

  return (
    <DeleteButton
      {...props}
      sx={{float:'right'}}
      label="Delete"
      redirect={false}
    />
  )
});

export const TranslationKeysShow = (props:ShowProps) => {
  return (
    <Show
      {...props}
      title={<TranslationKeysTitle />}
    >
      <SimpleShowLayout>
        <HeuristicReturnButton
          origins={['/TranslationKey', '/ContentPage']}
          defaultPath='/TranslationKey'
        />
        <TextField source='key' label='Translation key' />
        <DateField source='createdAt' label='Created at' />
        <ReferenceManyField
          fullWidth
          sort={{ field: 'languageID', order: 'DESC' }}
          label="Translations"
          reference="Translation"
          target="translationKeyID" >
          <Datagrid
            rowClick="edit"
            bulkActionButtons={false}
          >
            <LanguageNameField label='Language'/>
            <TextField label="Translation text" source="value" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <DeleteTranslationButton />
          </Datagrid>
        </ReferenceManyField>
        <CreateTranslationButton />
      </SimpleShowLayout>
    </Show >
  );
}
