import { TextField } from '@mui/material';
import { Controller} from 'react-hook-form';
import { Validate } from '../../utils';
import { UseFormReturn } from 'react-hook-form/dist/types';

export interface UsernameForm { username:string }

export const UsernameInput = (props:{formController: UseFormReturn<UsernameForm & any,any>}) => {

  const {control, getValues} = props.formController;

  return (
    <Controller 
      name='username'
      control={control}
      defaultValue=''
      rules={
        {
          required: 'Username is required.',
          validate: () => {
            if(Validate.email(getValues('username'))) return true;
            return 'Must be a valid email address.';
          }
        }
      }
      render={({field, fieldState}) => {
        return (
          <TextField
            label='e-mail'
            variant='standard'
            type = 'email'
            autoComplete='username'
            required={true}
            error={fieldState.error !== undefined}
            helperText={fieldState.error?.message}
            {...field}
          />
        )
      }}
    />
  )
}
