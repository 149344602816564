
import React from "react";

import {
  List,
  ListProps,
  Datagrid,
  CreateButton,
  TopToolbar,
  TextInput,
  DateField,
  BooleanField,
  TextField,
  BulkDeleteButton,
} from 'react-admin';

import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';


import { LanguageField } from "../../components";

const DocumentFileFilters = [
  <TextInput
    label="Search file names"
    source="documentFileName.contains"
    alwaysOn
    resettable
    key='searchFilter'
  />
];

export const DocumentFileList = (props:ListProps) => {

  return (

    <List
      {...props}
      title='Documents'
      exporter={false}
      actions={<DocumentFileActions/>}
      filters={DocumentFileFilters}
      bulkActionButtons={<BulkDeleteButton/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
      >
        <TextField label='Title' source='title'/>
        <TextField label='File name' source='documentFileName'/>
        <BooleanField
          label='Restricted access'
          source='protected'
          TrueIcon={HttpsIcon}
          FalseIcon={LockOpenIcon}
        />
        <LanguageField sortable={false}/>
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List >
  )
};


const DocumentFileActions = () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='add document'/>
    </TopToolbar>
  );
}




