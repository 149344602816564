
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  ProviderProps
} from "react";
import {useLocation} from 'react-router-dom';

export const HistoryContext = createContext<Array<string>>([])

export const HistoryProvider = (props: Omit<ProviderProps<Array<string>>,'value'>) => {
  const [history, setHistory] = useState<Array<string>>([]);
  const location = useLocation();

  useEffect(() => {
    if (history[0] !== location.pathname) {
      setHistory([location.pathname].concat(history).slice(0,20));
    }
  },[location])

  return <HistoryContext.Provider value={history} {...props}>{props.children} </HistoryContext.Provider>
}

export const useHistory = () => useContext(HistoryContext);
