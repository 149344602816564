import React from "react";

import {
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayFieldProps
} from 'react-admin'


export type LanguageInputFieldProps =
  Omit<ReferenceArrayFieldProps, 'reference' | 'label' | 'children'> &
  {
    label?:string
  }

export const LanguageInputField = (props: LanguageInputFieldProps) => {
  const {label,source} = props;
  return (
    <ReferenceArrayInput
      source={source ?? 'languages'}
      reference='Language'
      {...props}
    >
      <SelectArrayInput
        label ={label ?? 'Localisations'}
        optionText='displayName'
        variant='standard'
      />
    </ReferenceArrayInput>
  )
}
