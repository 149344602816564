import { useEffect, useState } from 'react';
import { useLogin } from 'react-admin';
import { Button, Stack, Alert, Collapse } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LoginParams, LoginChallenge } from '../../services';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AuthTab, SignInProps } from './authenticationPage';
import { UsernameInput } from './usernameInput';
import { PasswordInput } from './passwordInput';

export const SignInForm = (props:SignInProps) => {

  const {changeTab, setChallenge} = props;
  const formController = useForm<LoginParams>({mode:'onChange'});
  const {reset, handleSubmit, formState: {isDirty, isSubmitting, isValid}} = formController;
  const [loginFailed, setLoginFailed] = useState(false);
  const login = useLogin();

  const onSubmit: SubmitHandler<LoginParams> = async (data):Promise<void> => {
    try {
      await login(data);
    } catch (error:any | LoginChallenge) {
      if (error instanceof LoginChallenge && error.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setChallenge(error);
        changeTab(AuthTab.NewPasswordChallenge);
      } else {
        setLoginFailed(true);
      }
      reset();
    }
  }

  useEffect(() => {
    isDirty && setLoginFailed(false);
  }, [isDirty]);

  const goToPasswordReset = () => {
    changeTab(AuthTab.PasswordReset);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={1}>

          <UsernameInput formController={formController} />
          <PasswordInput formController={formController} />

          <Collapse in={loginFailed}>
            <Alert severity='error'>
              Incorrect username or password.
            </Alert>
          </Collapse>

          <LoadingButton 
            type='submit'
            loading={isSubmitting}
            disabled={!isValid || !isDirty}
            variant='contained'
            color='primary'
          >
            sign in
          </LoadingButton>

          <Button size='small'onClick={goToPasswordReset}>forgot password?</Button>

        </Stack>
      </form>
    </>
  )
}



