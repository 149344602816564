import React, { useState, useEffect } from 'react';
import {
  createFilterOptions,
  Autocomplete,
  TextField,
  Stack,
  Button
} from '@mui/material';

import {useWatch} from 'react-hook-form';

import { useGetList, Labeled, useInput } from 'react-admin';
import { ImageFile } from '../../models';
import { DatastorePagination } from '../../services';
import AddIcon from '@mui/icons-material/Add';

export const AddImageInput = () => {

  const sequenceInput = useInput({source: 'sequence'})
  const sequenceItemIds = useWatch({name:'sequence', defaultValue:[]});

  const imageListInput = useInput({source: 'imageFiles'});
  const imageListItemIds = useWatch({name:'imageFiles', defaultValue:[]});

  const [selectedImage, setSelectedImage] = useState<ImageFile|null>(null);
  const {data:imageFiles, isLoading} = useGetList<ImageFile>('ImageFile', {pagination:DatastorePagination.All});

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options:Array<ImageFile>, state:any):Array<ImageFile> => {
    return (defaultFilterOptions(options, state) as Array<ImageFile>)
     .filter(img => !(sequenceInput.field.value ?? []).includes(img.id))
     .slice(0, 100) as Array<ImageFile>;
  }

  const addImage = () => {
    if (selectedImage) {
      const {id} = selectedImage;

      const updatedSequence = sequenceItemIds.concat([id]);
      sequenceInput.field.onChange(updatedSequence);

      const updatedImageList = imageListItemIds.concat([id]);
      imageListInput.field.onChange(updatedImageList);

      setSelectedImage(null);
    }
  }

  return (
    <Labeled>
      <Stack direction='row' alignItems='flex-end' gap='10px'>
        <Autocomplete
          sx={{minWidth:'300px'}}
          options={imageFiles ?? []}
          filterOptions={filterOptions}
          loading={isLoading}
          getOptionLabel={(option) => option.imageFileName}
          onChange={(event,value,reason,details) => {setSelectedImage(value)}}
          value={selectedImage}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.imageFileName}
              </li>
            );
          }}
          renderInput={(params) =>
            <TextField
              placeholder='search image...'
              multiline
              variant='standard'
              {...params}
              label="Select Image"
            />}
        />
        <Button
          size='medium'
          startIcon={<AddIcon />}
          onClick={addImage}
        >Add Image</Button>
      </Stack>
    </Labeled>
  );
}

