import React, {useEffect} from 'react';
import {
  FunctionFieldProps,
  FunctionField,
  Datagrid,
  DateField,
  EmailField,
  List,
  ListProps,
  TextField,
  useListContext,
  CreateButton,
  TextInput,
  TopToolbar,
  useGetIdentity,
  TextInputProps
} from 'react-admin';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Chip, Button, Toolbar, Box } from '@mui/material'
import {Routes,Route} from 'react-router-dom'

import { GroupNames, User } from '../../services'; 
import { UserCreateDialog } from './userCreate';
import { UserEditDialog } from './userEdit';
import { useHasRole } from '../../components';

export const UserList = (props: ListProps) => {

  const isAdmin = useHasRole([GroupNames.Admin])
  const {isLoading:isLoadingIdentity, identity}= useGetIdentity()

  const isRowSelectable = (record: User): boolean => {
    if (!isLoadingIdentity && record.id === identity!.id) return false;
    if (isAdmin) return true;
    if (record.group && record.group === GroupNames.Customer) return true;
    return false;
  }

  return (
    <>
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={<UserPagination />}
      actions={<UserListAction />}
      filters={UserFilters}
      perPage={25}
    >
      <Datagrid
        hover={isAdmin}
        isRowSelectable={isRowSelectable}
        rowClick={isAdmin ? 'edit': undefined}
      >
        <EmailField source="email" sortable={false} />
        <StatusField source='status' label='Status' sortable={false}/>
        <TextField source='group' label='User Type' sortable={false}/>
        <DateField source="createdAt" label='Created At' sortable={false} />
        <DateField source="updatedAt" label='Updated At' sortable={false} />
      </Datagrid>
    </List>
    <Routes>
      <Route path='/create' element={ <UserCreateDialog/>} />
      <Route path='/:id' element={<UserEditDialog/>} />
    </Routes>
    </>
  )
}

const StatusField = (props:Omit<FunctionFieldProps,'render'>) => {
  return (
    <FunctionField {...props}
      render={(record: User) => {
        const {status} = record;

        switch (status){
          case 'UNCONFIRMED':
          case 'FORCE_CHANGE_PASSWORD':
            return <Chip label='confirmation pending' color='info' variant='outlined' size='small' />
          case 'CONFIRMED':
            return <Chip label='confirmed' color='success' size='small' />
          default:
            return <Chip label={status.toLowerCase().replace('_', ' ')} color='error' variant='outlined' size='small' />
        }
      }}
    />
  )
}

const UserListAction = () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='invite user' icon={<ForwardToInboxIcon/> }/>
    </TopToolbar>
  );
}

const UserPagination = () => {
  const { page, hasPreviousPage, hasNextPage, setPage} = useListContext();
  if (!hasPreviousPage && !hasNextPage) return null;
  return (
    <Toolbar>
      {hasPreviousPage && (
        <Button
          key="previous"
          onClick={() => setPage(page - 1)}
          startIcon={<ChevronLeft />}
        >
          Previous
        </Button>
      )}
      <Box width='100%'/>
      {hasNextPage && (
        <Button
          key="next"
          onClick={() => setPage(page + 1)}
          endIcon={<ChevronRight />}
        >
          Next
        </Button>
      )}
    </Toolbar>
  )
}

const UserFilters = [
   // " and \ will cause an exception in the Lambda so we'll only allow common characters in emails
  <TextInput
    label="email starts with"
    source='email'
    alwaysOn
    resettable
    parse={
      (value:string)=> value.replace(/[^a-zA-Z0-9!#$%&'*+-/=?^_`{|}~(),:;<>@[\]]/,'')
    }
  />
]

