import React  from 'react';

import {
  required,
  SimpleForm,
  TextInput,
  Create,
  CreateProps
} from 'react-admin';

import { SaveCancelToolbar, } from '../../components';
import { MediaSequenceInput } from './sequenceInput';
import { MediaLookupProvider } from './mediaLookup';
import { AddImageInput } from './addImageInput';
import { AddVideoInput } from './addVideoInput';
import {
  LanguageLookupProvider,
  UrlCacheProvider
} from '../../components';

export const PlaylistCreate = (props: CreateProps) => {

  return (
    <Create
      {...props}
      title='Add Playlist'
      redirect='list'
    >
      <SimpleForm redirect='list' toolbar={<SaveCancelToolbar />}>
        <MediaLookupProvider>
          <LanguageLookupProvider>
            <UrlCacheProvider>
              <TextInput
                source="name"
                variant="standard"
                validate={required()}
              />
              <MediaSequenceInput />
              <AddImageInput/>
              <AddVideoInput/>
            </UrlCacheProvider>
          </LanguageLookupProvider>
        </MediaLookupProvider>
      </SimpleForm>
    </Create>
  );
}
