import React  from "react";

import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  BulkDeleteButton,
  TopToolbar,
  CreateButton
} from 'react-admin';


const PlaylistFilters = [
  <TextInput
    label="Search name"
    source="name.contains"
    resettable
    alwaysOn
    key='searchFilter'
  />
];

export const PlaylistList = (props:ListProps) => {

  return (
    <List
      {...props}
      title='Playlists'
      exporter={false}
      filters={PlaylistFilters}
      actions={<PlaylistActions/>}
      bulkActionButtons={<BulkDeleteButton/>}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField source="name"/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>
    </List >
  )
};

const PlaylistActions = () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='add playlist'/>
    </TopToolbar>
  );
}
