import {Box, Divider} from '@mui/material';
import {Imprint, PrivacyPolicy, TermsOfUse} from "./text";
import { Markdown } from './markdown';
import { ReactAdminTheme } from '../../theme';
import {ThemeProvider} from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({name: 'LegalPage'})(theme => ({
  root: {
    background: theme!.palette!.background!.default,
    height: '100vh'
  },
  textContainer: {
      padding: '36px 0px',
      maxWidth: '800px',
      marginLeft: 'auto',
      marginRight: 'auto',
  }
}));


export const LegalPage = () => {
  return (
    <ThemeProvider theme={ReactAdminTheme}>
      <LegalTextView/>
    </ThemeProvider>
  )
}

const LegalTextView = () => {
  const {classes} = useStyles();
  return (
      <Box className={classes.root}>
        <Box className={classes.textContainer}>
          <Markdown>{Imprint}</Markdown>
          <Box height = '1.5em'/>
          <Divider/>
          <Box height = '1em'/>
          <Markdown>{PrivacyPolicy}</Markdown>
          <Box height = '1.5em'/>
        {/*
          <Divider/>
          <Box height = '1em'/>
          <Markdown>{TermsOfUse}</Markdown>
        */}
        </Box>
      </Box>
  )
}

