
import React, {useRef} from "react";

import {
  Edit,
  EditProps,
  SimpleForm,
  FileField,
  FileInput,
  TextInput,
  useRecordContext,
  required,
  RaRecord
} from 'react-admin';

import {
  SaveCancelToolbar,
  LanguageInputField,
  DocumentPlaceholder
} from "../../components";

import { useNavigate } from 'react-router-dom';
import { PutResult } from '@aws-amplify/storage';
import { cancelFileUploads } from "../../services";
import { maxFileSize } from "../../utils";
import { MaxDocumentSize, transformDocumentRecord } from "./documentFileUtils";
import { DocumentFileDetails } from "./documentfileDetails";
import { DocumentFile } from "../../models";

const MediaFilesTitle = () => {
  const record = useRecordContext<DocumentFile>()
  return <span>Document - { record ? record.documentFileName: '' }</span>
}

export const DocumentFileEdit = (props:EditProps) => {

  const uploadList = useRef<Promise<PutResult>[]>([]);
  const navigate = useNavigate();

  const transform = (record: RaRecord): RaRecord => transformDocumentRecord(record as DocumentFile, uploadList);
  const onCancel = () => {
    cancelFileUploads(uploadList);
    navigate(-1);
  }

  return (

    <Edit
      title={<MediaFilesTitle />}
      {...props}
      transform={transform}
      actions={false}
      mutationMode='pessimistic'
      aside={<DocumentFileDetails/>}
    >

      <SimpleForm
        toolbar={<SaveCancelToolbar onCancel={onCancel}/>}
      >
        <LanguageInputField source='languages'/>
        <TextInput source='title' variant='standard' validate={required()}/>
        <FileInput
          label='Upload new document'
          source='_documentStorageKey'
          accept='application/pdf,.doc'
          validate={[
            maxFileSize(MaxDocumentSize),
          ]}
          placeholder={
            <DocumentPlaceholder
              fileTypes={['*.pdf']}
              maxFileSize={MaxDocumentSize}
            />
          }
        >
          <FileField
            source="_documentStorageKey"
            title="documentFileName"
            src="_documentStorageKey"
            target="_blank"
          />
        </FileInput>
      </SimpleForm>
    </Edit>
  )

};
