import { makeStyles } from './makeStyle'

export const useMediaDropFieldStyle = makeStyles({ name: 'MediaDropField' })(
  (theme) => ({
    Placeholder: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignContent: 'space-around',
      alignItems: 'center',
      margin: '4px',
    },
    Icon: {
      display: 'inline-flex',
      color: '#d7d7d7',
      fontSize: '3em',
      marginBottom: '8px',
    },
    Text: {
      color: '#9F9F9F',
    },
  })
)
