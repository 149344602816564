import React, {useState, useEffect} from "react";

import {
  Create, CreateProps,
  useDataProvider,
  SelectInput,
  SimpleForm,
  useGetList,
  useRefresh,
} from "react-admin";

import { SaveCancelToolbar } from "../../components";
import { FormError, Locales, getLanguageNativeName, getLanguageDisplayName} from "../../utils"

import { Language } from "../../models";
import { DeepWritable } from "ts-essentials";
import {DatastorePagination} from '../../services';

interface LanguageChoice {
  id: string,
  name: string,
  disabled: boolean
}

export const LanguageCreate = (props:CreateProps) => {

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const {data: languages, isLoading: languagesLoading} = useGetList('Language', {pagination:DatastorePagination.All});

  const [choices, setChoices] = useState<LanguageChoice[]>([]);

  const transform = (record:DeepWritable<Language>) => {
    record.nativeName = getLanguageNativeName(record.locale);
    record.displayName = getLanguageDisplayName(record.locale);
    record.published = false;
    return { ...record };
  }

  useEffect(() => {
    if (!languagesLoading && languages) {
      const languageLocales = languages.map(l => l.locale);
      const langChoice =
        ["de", "en", "fr", "nl", "it", "es", "pt", "pl", "ru", "ja", "zh-Hans", "ko"]
        .map(locale => {
          return {
            id: locale,
            name: Locales[locale].displayName,
            disabled: languageLocales.includes(locale)
          }
        });
      setChoices(langChoice);
    }
  }, [languages, languagesLoading])


  // fetch the most recent state to check for uniqueness
  const languageIsUnique = async (data:Partial<Language>) => {
    const errors:FormError<Language> ={};
    const languages = await dataProvider.getList(
      'Language',
      {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'locale', order: 'DESC' },
        filter: { locale: { eq:data.locale } }
      }
    )
    if (languages.total! > 0) {
      errors.locale= 'language already exists';
      refresh();
    }
    return errors
  };


  return (
    <Create
      {...props}
      title='Add Language'
      transform={transform}
    >
      <SimpleForm validate={languageIsUnique} toolbar={<SaveCancelToolbar/>}>
        <SelectInput label="Select Language" choices={choices} source="locale" variant="standard" />
      </SimpleForm>
    </Create>
  )
}
