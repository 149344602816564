import {
  Card, Grid, CardContent,
  Divider, Avatar, Collapse,
  Box
} from '@mui/material';

import { EnrxLogoBlack } from '../../assets/logo/enrx-logo-black';

import { useState, useCallback } from 'react';

import { makeStyles } from 'tss-react/mui';
import { SignInForm} from './signinForm'
import { PasswordResetWizzard } from './passwordResetForm';
import {LoginChallenge} from '../../services';
import {NewPasswordChallengeForm} from './newPasswordChallengeForm';

const useStyles = makeStyles({ name: 'Authenticator' })((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignContent: 'center'
  }
}))

export const AuthPage = () => {
  const {classes} = useStyles();
  return (
      <Grid
        className={classes.background}
        container
        direction='column'
      >
        <Grid item>
          <Authenticator/>
        </Grid>
      </Grid>
  )
}

const TitleLogo = () => {
  return (
    <Box width="150px" marginY="28px">
      <EnrxLogoBlack/>
    </Box>
  )
}

export const enum AuthTab {SignIn, NewPasswordChallenge, PasswordReset}
export interface AuthTabProps {changeTab:(tab:AuthTab)=>void}
export interface SignInProps extends AuthTabProps {setChallenge: React.Dispatch<React.SetStateAction<LoginChallenge|undefined>>}
export interface AuthChallengeProps extends AuthTabProps {challenge: LoginChallenge|undefined};

const Authenticator = () => {
  const [loginChallenge, setLoginChallenge] = useState<LoginChallenge | undefined>(undefined);
  const [tab, setTab]  = useState(AuthTab.SignIn);
  const changeTab = useCallback((state: AuthTab) => {
    setTab(state);
  }, [setTab])

  return (
    <Card sx={{minWidth:'333px'}}>
      <CardContent sx={{display:'flex', justifyContent:'center'}}>
        <TitleLogo/>
      </CardContent>
      <Divider/>
      <Collapse in={tab === AuthTab.SignIn} appear={false} unmountOnExit={true}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column'}}>
          <SignInForm changeTab={changeTab} setChallenge={setLoginChallenge}/>
        </CardContent>
      </Collapse>
      <Collapse in={tab === AuthTab.NewPasswordChallenge} appear={false} unmountOnExit={true}>
        <CardContent sx={{display: 'flex', flexDirection: 'column'}}>
          <NewPasswordChallengeForm challenge={loginChallenge} changeTab={changeTab}/>
        </CardContent>
      </Collapse>
      <Collapse in={tab === AuthTab.PasswordReset} appear={false} unmountOnExit={true}>
        <CardContent sx={{display: 'flex', flexDirection: 'column'}}>
          <PasswordResetWizzard changeTab={changeTab}/>
        </CardContent>
      </Collapse>
    </Card>
  )
}



