
import React from "react";

import {
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  required,
  useRecordContext
} from 'react-admin';

import {
  SaveCancelToolbar,
} from '../../components';

import {
  Box,
  Grid
} from '@mui/material';

import { ContentPage } from '../../models';
import { ContentPageDetails } from './contentPageDetails';
import { TranslationKeyInput } from './translationKeyInput';
import { PlaylistReferenceInput } from './playlistInput';
import { DocumentListInput } from './documentListInput';
import { AddDocumentInput } from './addDocumentInput';

import {LanguageLookupProvider} from '../../components';
import {DocumentLookupProvider} from './documentLookup';

const ContentPageTitle = () => {
  const record = useRecordContext<ContentPage>()
  return <span>Page - { record ? record.uniqueName: '' }</span>
}

export const ContentPageEdit = (props:EditProps) => {


  return (

    <Edit
      title={<ContentPageTitle/>}
      {...props}
      actions={false}
      mutationMode='pessimistic'
      aside={<ContentPageDetails/>}
    >
      <DocumentLookupProvider>
        <LanguageLookupProvider>
          <SimpleForm toolbar={<SaveCancelToolbar />} >
            <TextInput
              disabled={true}
              source='uniqueName'
              label='Name'
              variant='standard'
              validate={required()}
            />
            <Box maxWidth='750px'>
              <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid item >
                  <TranslationKeyInput label='Title Translation Key' source='titleTranslationKey'/>
                </Grid>
                <Grid item >
                  <TranslationKeyInput label='Slogan Translation Key' source='sloganTranslationKey'/>
                </Grid>
                <Grid item >
                  <TranslationKeyInput label='Features Translation Key' source='featuresTranslationKey'/>
                </Grid>
                <Grid item >
                  <TranslationKeyInput label='Benefits Translation Key' source='benefitsTranslationKey'/>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{height:'1.5rem'}}/>
            <PlaylistReferenceInput label='Playlist'/>
            <Box sx={{height:'2rem'}}/>
            <DocumentListInput/>
            <AddDocumentInput/>
          </SimpleForm>
        </LanguageLookupProvider>
      </DocumentLookupProvider>
    </Edit>
  )
};
