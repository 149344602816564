
import {useEffect, useState} from 'react';

import {usePermissions} from 'react-admin';
import {GroupName} from '../../services';

export const useHasRole = (roles: Array<GroupName>): boolean => {

  const {permissions, isLoading} = usePermissions();
  const [hasRole, setHasRole] = useState(false);

  useEffect( () => {
    if(!isLoading){
      setHasRole(((permissions as Array<GroupName>) ?? []).some(p => roles.includes(p)));
    }
  },[permissions,isLoading]);

  return hasRole;
}
