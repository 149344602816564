
export const maxFileSize = (maxByteSize: number) =>
  (value: {rawFile:File} | undefined, values: any): string | {message: string, args: any} | undefined => {
    if (!value) return undefined;
    const {rawFile} = value;

    if (rawFile.size > maxByteSize) {
      return `File exceeds max. size: ${(rawFile.size / 1000000).toPrecision(3)}MB (allowed max.: ${(maxByteSize / 1000000).toPrecision(2)}MB)`;
    }

    return undefined;
  }
