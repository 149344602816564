
import { API } from "@aws-amplify/api";
import { HttpError } from "react-admin";
import axios, {AxiosError } from 'axios';
import {isError, isString} from "lodash";

export class ApiClient {

  static async get(path: string, params: Record<string, unknown>) {
    const init = {
      queryStringParameters: params,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try{
      return await API.get("UserPoolApi", path, init);
    } catch (ex) {
      throw this.formatError(ex);
    }
  }

  static async delete(path: string, body: Record<string, unknown>) {
    const params = {
      body,
      headers: {
        "Content-Type": "application/json",
      }
    }

    try {
      return await API.del("UserPoolApi", path, params);
    } catch (ex) {
      throw this.formatError(ex);
    }
  }

  static async post(path: string, body: Record<string, unknown>) {
    const params = {
      body,
      headers: {
        "Content-Type": "application/json",
      }
    }
    try {
      const response = await API.post("UserPoolApi", path, params);
      return response;
    }
    catch (ex) {
      throw this.formatError(ex);
    }
  }

  static async put(path: string, body: Record<string, unknown>) {
    const params = {
      body,
      headers: {
        "Content-Type": "application/json",
      }
    }
    try {
      return await API.put("UserPoolApi", path, params);
    }
    catch (ex) {
      throw this.formatError(ex);
    }
  }

  static formatError(exception: unknown): HttpError{
    if (axios.isAxiosError(exception)) {
      const error = exception as AxiosError;
      return new HttpError(error.response?.data.message, error.response?.status);
    }
    else if (isError(exception)) {
      const error = exception as Error;
      return new HttpError(error.message, 500);
    }
    else if (isString(exception)) {
      return new HttpError(exception as string, 500);
    }
    else {
      return new HttpError('An error occurred', 500);
    }
  }
}

