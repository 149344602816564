import {Auth} from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Stack,
  Alert,
  Collapse,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { ConfirmedPasswordInput } from './confirmedPasswordInput';
import {useForm, SubmitHandler} from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send';
import { AuthTab, AuthTabProps } from './authenticationPage';
import { ConfirmationCodeInput } from './confirmationCodeInput';
import _ from 'lodash';
import {UsernameInput} from './usernameInput';
import {PasswordPolicyInfo} from '../../utils';

interface SendCodeStepProps {
  nextStep: () => void,
  setUserName: React.Dispatch<React.SetStateAction<string>>
}

interface UpdatePasswordStepProps {
  goToSignIn: () => void,
  previousStep: () => void,
  username: string;
}

export const PasswordResetWizzard = (props:AuthTabProps) => {
  const {changeTab} = props;
  const [activeStep, setActiveStep] = useState(0);
  const [username ,setUserName] = useState('');

  const nextStep = () => {
    const step = Math.min(activeStep + 1,1);
    setActiveStep(step);
  }
  const previousStep = () => {
    const step = Math.max(activeStep - 1,0);
    setActiveStep(step);
  }

  const returnToSignIn = () => {
    changeTab(AuthTab.SignIn);
  }

  return (
    <>
      <Button sx={{marginBottom: '2px'}} size='small'onClick={returnToSignIn}>return to sign in</Button>
      <Stepper orientation='vertical' activeStep={activeStep}>
        <Step>
          <SendCodeStep
            nextStep={nextStep}
            setUserName={setUserName}
          />
        </Step>
        <Step>
          <UpdatePasswordStep
            goToSignIn={returnToSignIn}
            previousStep={previousStep}
            username={username}
          />
        </Step>
      </Stepper>
    </>
  );
}

interface SendCodeParams  {username:string}

const SendCodeStep = (props:SendCodeStepProps) => {
  const {nextStep, setUserName} = props;
  const [error, setError] = useState(false);
  const formController = useForm<SendCodeParams>({mode:'onChange'});
  const {reset, handleSubmit, formState: {isValid, isDirty, isSubmitting}} = formController;

  const sendResetCode: SubmitHandler<SendCodeParams> = async (data):Promise<void>=> {
    try {
      await Auth.forgotPassword(data.username);
      setUserName(data.username);
      nextStep();
    }
    catch (error) {
      setError(true);
      reset();
    }
  }

  useEffect(() => {
    isDirty && setError(false);
  }, [isDirty]);

  return (
    <>
      <StepLabel>send reset code</StepLabel>
      <StepContent>
        <form onSubmit={handleSubmit(sendResetCode)}>
          <Stack direction="column" gap={1}>

            <UsernameInput formController={formController} />

            <Collapse in={error}>
              <Alert severity="error">
                {"Email doesn't belong to a user."}
              </Alert>
            </Collapse>

            <LoadingButton
              type="submit"
              size="small"
              variant="contained"
              startIcon={<SendIcon />}
              loading={isSubmitting}
              disabled={!isDirty ||!isValid}
            >
              send reset code
            </LoadingButton>

          </Stack>
        </form>
      </StepContent>
    </>
  )
}

interface UpdatePasswordParams {
  code: string;
  password: string;
  confirmPassword: string;
}

const UpdatePasswordStep = (props:UpdatePasswordStepProps) => {
  const {goToSignIn, username} = props;
  const [error, setError] = useState<any|null>(null);
  const [loading, setLoading] = useState(false);
  const formController = useForm<UpdatePasswordParams>({mode:'onChange'});
  const { handleSubmit, formState:{errors, isValid, isDirty}} = formController;

  const updatePassword: SubmitHandler<UpdatePasswordParams> = async (data): Promise<void> => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(username, data.code, data.password);
      goToSignIn();
    }
    catch (error) {
      setError(error as any);
    }
    setLoading(false);
  }

  const needsHint = (errors: any):boolean => {
    return _.get(errors, 'password.type') === 'validate';
  }

  return (
    <>
      <StepLabel>set new password</StepLabel>
      <StepContent>
        <form onSubmit={handleSubmit(updatePassword)}>
          <Stack direction="column" gap={1}>

            <ConfirmationCodeInput formController={formController} />
            <ConfirmedPasswordInput formController={formController} />

            <Collapse in={error!==null || needsHint(errors)} sx={{maxWidth: '261px'}}>
              {error === null && needsHint(errors) &&
                <Alert severity="warning">
                  <span>{PasswordPolicyInfo}</span>
                </Alert>
              }
              {error !== null &&
                <Alert severity="error">
                  {_.get(error, 'message', '')}
                </Alert>
              }
            </Collapse>

            <LoadingButton
              type="submit"
              size="small"
              variant="contained"
              disabled={!isValid || !isDirty}
              loading={loading}
            >
              set new password
            </LoadingButton>

          </Stack>
        </form>
      </StepContent>
    </>
  )

}
