import { Layout, LayoutProps } from "react-admin";
import { Box } from "@mui/material";
import { MenuBar } from "./menuBar";
import { MenuDrawer } from "./menuDrawer";
import { Footer } from "./footer";

export const AppLayout = (props: LayoutProps) => {
  return (
    <Layout {...props} menu={MenuDrawer} appBar={MenuBar}>
      <div>{props.children}</div>
      <Box flex="auto" />
      <Footer legalPath="/legal" hideOnPaths={["/imprint"]}/>
    </Layout>
  )
}
