import {
  DetailsPanel,
  DetailsDateField,
  DetailsImageField,
  DetailsTextField,
  DetailsBooleanField
} from '../../components'

import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const ImageFileDetails = () => {
  return (
    <DetailsPanel size="large">
      <DetailsImageField label="Image" source="imageStorageKey" />
      <DetailsTextField label="File name" source="imageFileName" />
      <DetailsBooleanField
        label='Restricted acess'
        source='protected'
        TrueIcon={HttpsIcon}
        FalseIcon={LockOpenIcon}
      />
      <DetailsDateField label="Created at" source="createdAt" />
      <DetailsDateField label="Updated at" source="updatedAt" />
    </DetailsPanel>
  )
}
