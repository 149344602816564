
import React ,{useEffect, useState}  from 'react';

import {
  useInput,
  useRecordContext,
  Labeled,
  Datagrid,
  TextField,
  BooleanField
} from 'react-admin';

import { Button } from '@mui/material';
import {useWatch} from 'react-hook-form';
import { LanguageField } from "../../components";
import { useDocumentLookupContext, DocumentFileInfo} from './documentLookup';
import HttpsIcon from '@mui/icons-material/Https';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const DocumentListInput = () => {

  const documentFileIds = useWatch({name: 'documentFiles', defaultValue: []});
  const [documentsLoading, documentLookup] = useDocumentLookupContext();

  const [documents, setDocuments] = useState<DocumentFileInfo[]>([]);

  useEffect(() => {
    if (documentLookup && documentFileIds){
      setDocuments(
        (documentFileIds as string[])
          .filter((id) => documentLookup.has(id))
          .map((id) => documentLookup.get(id) as DocumentFileInfo)
      );
    }
  }, [documentLookup, documentFileIds]);


  return (
     documents.length > 0 ?
    <Labeled fullWidth label='Documents' paddingTop={1}>
        {documentLookup &&
          <Datagrid
            data={documents}
            total={documents?.length ?? 0}
            isLoading={documentsLoading}
            hover={false}
            sort={{field: 'title', order: 'ASC'}}
          >
            <TextField label='Title' source='title'/>
            <BooleanField
              label='Access'
              source='protected'
              TrueIcon={HttpsIcon}
              FalseIcon={LockOpenIcon}
            />
            <LanguageField sortable={false}/>
            <RemoveDocumentButton/>
          </Datagrid>
          }
      </Labeled>
      : null
  );
}

const RemoveDocumentButton = () => {

  const document = useRecordContext();
  const documentListInput = useInput({source: 'documentFiles'})

  const removeItem= () => {
    if (!document) return;
    const updatedSequence = (documentListInput.field.value as Array<string>).filter(id => id !== document.id);
    documentListInput.field.onChange(updatedSequence);
  }

  return (
    <Button sx={{float:'right', minWidth:'100px'}} startIcon={<RemoveCircleIcon/>} onClick={removeItem}>Remove</Button>
  );
}
