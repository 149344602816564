import React from "react";

import {
  List,
  ListProps,
  Datagrid,
  CreateButton,
  TopToolbar,
  TextInput,
  DateField,
  TextField,
  BulkDeleteButton,
} from 'react-admin';

import { ContentPageListExpand } from "./contentPageListExpand";

const ContentPageFilters = [
  <TextInput
    label="Search Page names"
    source="uniqueName.contains"
    resettable
    alwaysOn
    key='searchFilter'
  />
];

export const ContentPageList = (props:ListProps) => {

  return (
    <List
      {...props}
      title='Pages'
      exporter={false}
      actions={<ContentPageActions/>}
      filters={ContentPageFilters}
      bulkActionButtons={<BulkDeleteButton/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
        expand={<ContentPageListExpand/>}
      >
        <TextField label='Name' source='uniqueName'/>
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List >
  )
};

const ContentPageActions = () => {
  return (
    <TopToolbar sx={{paddingY:2}}>
      <CreateButton  label='add Page'/>
    </TopToolbar>
  );
}
